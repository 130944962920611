import { useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { Box } from '@mui/material';

import { userWalletValidate } from '../../validates/userWelletValidate';
import { updateWallet } from '../../api/user/index';
import Input from '../../components/common/input/Input';
import Button from '../../components/common/button/Button';
import Select from '../../components/common/select/Select'
import {useNavigate} from "react-router-dom";

const chooseReasonOptions = [
  { label: 'Payment Method Failed', value: 'PAYMENT_METHOD_FAILED' },
  { label: 'Bank transfer issues', value: 'BANK_TRANSFER_ISSUES' },
  { label: 'Marketing Purposes', value: 'MARKETING_PURPOSES' },
  { label: 'Offline Payment', value: 'OFFLINE_PAYMENT' },
];

const UpdateUserWalletForm = ({handleCloseUpdateWalletModal}) => {
  const navigate = useNavigate()

  const {
    isLoading,
    mutate,
  } = useMutation(({ id, values }) => updateWallet({ id, values }));

  const handleUpdateWallet = (values, {setErrors}) => {
    const params = new URL(window.location).searchParams;
    const id = params.get('userId');

    mutate({ id, values }, {
      onSuccess: () => {
        enqueueSnackbar('User is successfully created', { variant: 'success' });
        handleCloseUpdateWalletModal()
      },
      onError: (res) => {
        const passwordError = res?.response?.data?.data?.find(obj => obj?.field === 'password')
        if(passwordError) {
          setErrors({enterPassword: passwordError?.message})
        }
      }
    });

  }

  return (
    <div className='UpdateUserWalletForm'>
      <Formik
        initialValues={{
          enterAmount: '',
          chooseReason: '',
          enterPassword: '',
        }}
        validationSchema={userWalletValidate}
        onSubmit={handleUpdateWallet}
      >
        { 
          ({ errors, touched }) => {
            return (
              <Form>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2rem',
                }}>
                  <Field
                    component={Input}
                    label='Increment by'
                    name='enterAmount'
                    id='enterAmount'
                    placeholder='Enter Amount'
                    type='number'
                    error={errors?.enterAmount && touched?.enterAmount}
                    helperText={errors?.enterAmount}
                  />
                  <Field
                    component={Select}
                    label='Choose Reason'
                    options={chooseReasonOptions}
                    name='chooseReason'
                    id='chooseReason'
                    error={errors?.chooseReason && touched?.chooseReason}
                    helperText={errors?.chooseReason}
                  />
                  <Field
                    component={Input}
                    type='password'
                    label='Enter password'
                    name='enterPassword'
                    id='enterPassword'
                    error={errors?.enterPassword && touched?.enterPassword}
                    helperText={errors?.enterPassword}
                  />
                </Box>
                <Box
                  sx={{
                    textAlign: 'center',
                    marginTop: '4rem',
                  }}
                >
                  <Button className='modal' isModal isLoading={isLoading}>
                    Save
                  </Button>
                </Box>
              </Form>
            )
          }
        }
      </Formik>
    </div>
  )

}
export default UpdateUserWalletForm;