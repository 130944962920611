import { FormHelperText, Box } from "@mui/material";
import { TimeField as MaterialTimeField } from "@mui/x-date-pickers";

import "./StylesTimeField.scss";

const TimeField = ({
  name,
  label,
  value,
  error,
  helperText,
  defaultValue,
  handleChange,
}) => {

  const isError = helperText?.length > 0;

  return (
    <Box
      className="wrapperSelectBlock"
      sx={{
        width: "100%",
      }}
    >
      <label className="wrapperSelectBlock__label">{label}</label>
      <Box>
        <MaterialTimeField
          name={name}
          error={isError}
          value={value}
          className="customInput"
          onChange={handleChange}
          defaultValue={defaultValue}
          sx={{
            "& .MuiInputBase-input": {
              display: "flex",
              alignItems: "center",
              fontSize: "1.8rem",
            },
            '& .MuiOutlinedInput-notchedOutline':
              {
                borderColor:isError ? "red" : "",
              },
          }}
          fullWidth
        />

        {error && (
          <FormHelperText
            sx={{
              color: "red",
              fontSize: "1.1rem",
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default TimeField;
