import HeadNavBarItem from './headNavBarItem/HeadNavBarItem';
import './StylesHeadNavBar.scss';

const HeadNavBar = ({ navbarLinks, handleChangeActiveHeadMenu, activeHeadNavMenuId, isNewUser, isDisableChangeTab }) => (
  <nav>
    <ul className='HeadNavBar'>
      {
        isNewUser &&
          <HeadNavBarItem
            handleChangeActiveHeadMenu={handleChangeActiveHeadMenu}
            label={navbarLinks[0]?.label}
            url={navbarLinks[0]?.url}
            activeHeadNavMenuId={0}
            idx={0}
            isDisableChangeTab={isDisableChangeTab}
          />
      }
      {
        !isNewUser && navbarLinks && navbarLinks.map((navItem, idx) => (
          <HeadNavBarItem
            handleChangeActiveHeadMenu={handleChangeActiveHeadMenu}
            key={`${idx}_${navItem.label}`}
            label={navItem.label}
            url={navItem.url}
            activeHeadNavMenuId={activeHeadNavMenuId}
            idx={idx}
            isDisableChangeTab={isDisableChangeTab}
          />
        ))
      }
    </ul>
  </nav>
)

export default HeadNavBar;