import { Box, Typography } from '@mui/material';

const TableEmptyState = () => (
  <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    <Typography variant='h2' gutterBottom>
      No Result found
    </Typography>
  </Box>
)

export default TableEmptyState;