import { fetchClient } from '../axiosinterceptor';

export const uploadImage = async (file) => {
  const { data } = await fetchClient().post('upload/image', {
    file,
  }, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  
  return data;
}