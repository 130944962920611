import { Field } from 'formik';

import Modal from '../common/modal/Modal';
import DropZone from '../common/dropZone/DropZone';
import circleIcon from '../../assets/icons/awesomeCheckCircle.svg';
import SprayMoneyBalance from '../sprayMoneyBalance/SprayMoneyBalance';
import UpdateUserWalletForm from '../../forms/updateUserWallet/UpdateUserWalletForm';
import { convertDate } from '../../utils/convertDate';

import Input from '../common/input/Input';
import './StylesUserInformatinBlock.scss';

const UserInformationBlock = ({
                                isShowUpdateWalletModal,
                                handleCloseUpdateWalletModal,
                                handleShowUpdateWalletModal,
                                imgFieldName,
                                imgType,
                                userData
                              }) => {
  return (
    <div className='UserInformationBlock'>
      <DropZone
        imgFieldName={imgFieldName}
        selectArtist={userData}
        imgType={imgType}
        label='Add User Image'
      />
      <div className='UserInformationBlock__user'>
        <div className='UserInformationBlock__user__data'>
          <Field
            component={Input}
            name='firstName'
          />
          <Field
            component={Input}
            name='lastName'
          />
        </div>
        <div className='UserInformationBlock__user__data'>
          <img src={circleIcon} alt='circle' />
          <span>Verified User</span>
        </div>
      </div>
      <div className='UserInformationBlock__event__data'>
        <div className='UserInformationBlock__event__data__stream--passes'>
          <span>{userData?.streamPassesCount || 0}</span>
          <span>Stream Passes</span>
        </div>
        <div className='UserInformationBlock__event__data__events'>
          <span>{userData?.eventsCount || 0}</span>
          <span>Events</span>
        </div>
      </div>
      <div className='UserInformationBlock__user__data__register--data'>
        <span>Register Date</span>
        <span>{userData?.createdAt ? convertDate(userData?.createdAt, '/') : ''}</span>
      </div>
      <SprayMoneyBalance
        handleShowUpdateWalletModal={handleShowUpdateWalletModal}
        balance={userData?.fundWallet}
      />
      {
        isShowUpdateWalletModal &&
          <Modal
            onClose={handleCloseUpdateWalletModal}
            title='Update User Wallet'
          >
            <UpdateUserWalletForm
              handleCloseUpdateWalletModal={handleCloseUpdateWalletModal}
            />
          </Modal>
      }
    </div>
  )
}

export default UserInformationBlock;