import { FormControlLabel, Radio } from '@mui/material';

const RadioButton = ({ label, field, props }) => (
  <FormControlLabel
    control={
      <Radio
        {...field}
        {...props}
      />
    }
    label={label}
  />
);

export default RadioButton;