import './StylesPage.scss';

const Page = ({ title, action, children }) => (
  <div className='Page'>
    <header className='Page__header'>
      <div>
        <span className='Page__header__title'>
          {title}
        </span>
      </div>
      <div className='Page__actions'>
        {action}
      </div>
    </header>
    <div className='Page__content'>
      {children}
    </div>
  </div>
)

export default Page;