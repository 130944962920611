import { useState, useEffect, useCallback } from "react"
import { useMutation } from "react-query"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import CountryList from "country-list-with-dial-code-and-flag"

import { createNewUser, getUser, updateUser } from "../../api/user"
import { userInformationSchema } from "../../validates/userInformationValidate"
import { convertDate } from "../../utils/convertDate"
import { MONTH } from "../../constants"
import Page from "../../components/common/page/Page"
import CrmLayout from "../../layouts/crmLayout/CrmLayout"
import ContactInfoForm from "../../forms/userManagement/contactInfo/ContactInfoForm"
import StreamPasses from "../../components/streamPasses/StreamPasses"
import Transactions from "../../components/transactions/Transactions"
import Management from "../../components/management/Management"
import Emails from "../../components/emails/Emails"
import Guests from "../../components/guests/Guests"

const USER_INFORMATION_TAB_INDEX = 0
const STREAM_PASS_TAB_INDEX = 1
const TRANSACTION_TAB_INDEX = 2
const EMAILS_TAB_INDEX = 3
const GUESTS_TAB_INDEX = 4
const MANAGEMENT_TAB_INDEX = 5

const UserInformationPage = () => {
  const navigator = useNavigate()
  const [activeHeadNavMenuId, setActiveHeadNavMenuId] = useState(0)
  const [isShowUpdateWalletModal, setIsShowUpdateWalletModal] = useState(false)
  const [isNewUser, setIsNewUser] = useState(false)
  const [userId, setUserId] = useState(false)

  const { isLoading, error, mutate } = useMutation(createNewUser)

  const {
    isLoading: getUserLoading,
    mutate: getUserMutate,
    data: userData,
  } = useMutation(getUser)

  const { isLoading: updateUserLoading, mutate: updateUserMutate } =
    useMutation(updateUser)

  const handleSubmit = useCallback(
    values => {
      if (isNewUser) {
        return mutate(
          {
            values,
            userId: "",
          },
          {
            onSuccess: () => {
              enqueueSnackbar("User is successfully created", {
                variant: "success",
              })
              navigator("/dashboard")
            },
            onError: error => {
              const errorData = error?.response?.data?.data
              errorData?.forEach(({ field, message }) =>
                enqueueSnackbar(`${field}:${message}`, { variant: "error" })
              )
            },
          }
        )
      }

      return updateUserMutate(
        {
          values,
          userId: userId,
        },
        {
          onSuccess: () => {
            enqueueSnackbar("User is successfully updated", {
              variant: "success",
            })
            navigator("/dashboard")
          },
          onError: () => {
            const errorData = error?.response?.data?.data
            errorData?.forEach(({ field, message }) =>
              enqueueSnackbar(`${field}:${message}`, { variant: "error" })
            )
          },
        }
      )
    },
    [isNewUser, userId]
  )

  const handleChangeActiveHeadMenu = id => setActiveHeadNavMenuId(() => id)
  const handleShowUpdateWalletModal = () =>
    setIsShowUpdateWalletModal(() => true)
  const handleCloseUpdateWalletModal = () =>
    setIsShowUpdateWalletModal(() => false)

  const changeTab = tab => {
    const params = new URL(window.location).searchParams
    const currentTab = params.get("tab")

    if (!currentTab)
      navigator(
        `${
          window.location.search ? window.location.search : "?"
        }&tab=contactInfo`
      )

    return window.location.search.replace(/(tab)=[^&]*/g, `${tab}`)
  }

  const navLinks = [
    { label: "Contact information", url: changeTab("tab=contactInfo") },
    { label: "Stream Passes", url: changeTab("tab=streamPasses") },
    { label: "Transactions", url: changeTab("tab=transactions") },
    { label: "Emails", url: changeTab("tab=emails") },
    { label: "Guests", url: changeTab("tab=guests") },
    { label: "Management", url: changeTab("tab=management") },
  ]

  useEffect(() => {
    const params = new URL(window.location).searchParams
    const id = params.get("userId")

    if (id) {
      getUserMutate(id)
      setUserId(id)
      return setIsNewUser(() => false)
    }

    setIsNewUser(() => true)
  }, [])

  useEffect(() => {
    const params = new URL(window.location).searchParams
    const tab = params.get("tab")

    if (!tab) return setActiveHeadNavMenuId(USER_INFORMATION_TAB_INDEX)
    if (tab === "emails") return setActiveHeadNavMenuId(EMAILS_TAB_INDEX)
    if (tab === "transactions")
      return setActiveHeadNavMenuId(TRANSACTION_TAB_INDEX)
    if (tab === "guests") return setActiveHeadNavMenuId(GUESTS_TAB_INDEX)
    if (tab === "management")
      return setActiveHeadNavMenuId(MANAGEMENT_TAB_INDEX)
    if (tab === "streamPasses")
      return setActiveHeadNavMenuId(STREAM_PASS_TAB_INDEX)
    if (tab === "contactInfo")
      return setActiveHeadNavMenuId(USER_INFORMATION_TAB_INDEX)
  }, [])

  const formattingPhoneData = () => {
    if (userData) {
      const code = CountryList.getAll().find(
        ({ data }) => data?.dial_code === userData?.data?.countryCode
      )

      if (!code) {
        return {
          code: "+27",
          phone: userData?.data?.phoneNumber,
        }
      }

      if (code) {
        const { dial_code } = code
        let replacePhone = ""

        if (dial_code) {
          replacePhone = userData?.data?.phoneNumber.slice(dial_code.length)

          return {
            code: dial_code,
            phone: replacePhone,
          }
        }
      }
    }

    return {
      code: "+27",
      phone: "",
    }
  }

  const formattingDate = () => {
    if (userData) {
      const result = convertDate(userData?.data?.birthday, " ").split(" ")
      const month = MONTH.find(({ value }) => value === Number(result[1]))

      return {
        day: result[0],
        month: { label: month?.label, value: month?.value },
        year: result[2],
      }
    }

    return {
      day: new Date().getDate(),
      month: { label: "January", value: 1 },
      year: new Date().getFullYear() - 18,
    }
  }

  return (
    <Page title="User Information">
      {!getUserLoading && (
        <Formik
          initialValues={{
            userName: userData?.data?.username,
            emailAddress: userData?.data?.email,
            countryCode: formattingPhoneData()?.code,
            phoneNumber: formattingPhoneData()?.phone,
            gender: userData?.data?.gender,
            birthday: formattingDate()?.day,
            month: formattingDate()?.month?.value,
            year: formattingDate()?.year,
            firstName: userData?.data?.firstName,
            lastName: userData?.data?.lastName,
            password: "",
            image: userData?.data?.image,
          }}
          validationSchema={userInformationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          <CrmLayout
            navbarLinks={navLinks}
            handleChangeActiveHeadMenu={handleChangeActiveHeadMenu}
            activeHeadNavMenuId={activeHeadNavMenuId}
            isShowUpdateWalletModal={isShowUpdateWalletModal}
            handleCloseUpdateWalletModal={handleCloseUpdateWalletModal}
            handleShowUpdateWalletModal={handleShowUpdateWalletModal}
            imgFieldName="image"
            imgType="imageType"
            userData={userData?.data}
            isNewUser={isNewUser}
          >
            {activeHeadNavMenuId === USER_INFORMATION_TAB_INDEX && (
              <ContactInfoForm
                userData={userData}
                isNewUser={isNewUser}
                isLoading={isLoading}
                updateUserLoading={updateUserLoading}
              />
            )}
            {activeHeadNavMenuId === STREAM_PASS_TAB_INDEX && <StreamPasses />}
            {activeHeadNavMenuId === TRANSACTION_TAB_INDEX && (
              <Transactions userId={userId} />
            )}
            {activeHeadNavMenuId === EMAILS_TAB_INDEX && (
              <Emails userId={userData?.data?.id} />
            )}
            {activeHeadNavMenuId === GUESTS_TAB_INDEX && (
              <Guests userId={userData?.data?.id} />
            )}
            {activeHeadNavMenuId === MANAGEMENT_TAB_INDEX && <Management />}
          </CrmLayout>
        </Formik>
      )}
    </Page>
  )
}

export default UserInformationPage
