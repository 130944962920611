import { fetchClient } from '../axiosinterceptor';

export const passesShare = async ({ orderItemId, userEmail }) => {
  const { data } = await fetchClient().post(`passes/share`, {
    orderItemId,
    userEmail
  });

  return data;
}

export const getUserPasses = async ({ userId, page, limit }) => {
  const { data } = await fetchClient().get(`users/${userId}/passes/limit/${limit}/page/${page}`);

  return data;
}

export const refundUserPasses = async ({ id, reason }) => {
  const { data } = await fetchClient().put(`orders/${id}/refund`, {
    reason: reason
  });

  return data;
}

export const removeStreamPass = async ({ id: { currentStreamPass } }) => {
  const { data } = await fetchClient().delete(`passes/pass-access/${currentStreamPass}`);

  return data;
}

export const createPasses = async () => {
  const { data } = await fetchClient().post('/passes', {
    
  })

  return data;
}

export const getSinglePass = async ({ eventId }) => {
  const { data } = await fetchClient().get(`passes/event/${eventId}`);

  return data;
}
