import { fetchClient } from "../axiosinterceptor";

export const getArtist = async ({ s }) => {
  const { data } = await fetchClient().get(
    `artists/${s.length > 0 ? "?s=" + s : ""}`
  );

  return data;
};

export const getArtistById = async ({ id }) => {
  const { data } = await fetchClient().get(`/artists/${id}`);

  return data;
};

export const createArtist = async ({ value }) => {
  const { data } = await fetchClient().post("/artists", {
    ...value,
  });

  return data;
};
