import { fetchClient } from '../axiosinterceptor';

export const markAsComplete = async ({ selectTransactionId }) => {
  return await fetchClient().put(`orders/${selectTransactionId}/complete`);
}

export const reopenOrder = async ({ selectTransactionId, reason }) => {
  return await fetchClient().put(`orders/${selectTransactionId}/reopen`, {
    reason
  });
}

export const validatePending = async ({ transactionId }) => {
  return await fetchClient().put(`orders/${transactionId}/validate`);
}

export const createOrder = async ({ sendData, notify }) => {
  const data = await fetchClient().post('orders/ticket', {
    ...sendData
  }, {
    params: {
      notify
    }
  });

  return data;
}