import { Box } from '@mui/material'
import './StylesSideBarMenuUserBottomAction.scss';
import LogoutMenu from "../../LogoutMenu/LogoutMenu";



const SideBarMenuUserBottomAction = () => {
  const data = JSON.parse(localStorage.getItem('user'));

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    
    >
      <LogoutMenu data={data} />
    </Box>
  )
}

export default SideBarMenuUserBottomAction;