// import * as React from 'react';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { FormControl } from '@mui/material';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// const BasicTimePicker = () => {

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <FormControl sx={{ width: '100%' }}>
//         <TimePicker
//           id="sendTime"
//           value={sendTime ? sendTime : null}
//           onChange={handleChange}
//           views={['time']}
//         />
//       </FormControl>
//     </LocalizationProvider>
//   );
// }

// export default BasicTimePicker;

import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useFormikContext } from "formik";

export default function BasicTimePicker() {
  // const { values, setFieldValue } = useFormikContext();
  const { setFieldValue, values: { sendOnTime } } = useFormikContext();
  // const sendTime = values.sendTime; // Assuming you have a "sendTime" field in your form

  const handleChange = (time) => {
    setFieldValue("sendOnTime", time);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          value={sendOnTime ? dayjs(sendOnTime) : null}
          onChange={(newValue) => setFieldValue("sendOnTime", newValue?.format())}
        />
    </LocalizationProvider>
  );
}
