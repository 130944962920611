import {
  Select,
  MenuItem,
  Grid,
  TextField,
  Autocomplete,
  Box,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";

import FilterIcon from "../svg/FilterIcon";
import SearchIconSvg from "../svg/SeachIcon";

const TableFilter = ({
  selectOptions = [],
  purchaseTypeOptions = [],
  handleFilters,
  textFieldName,
  textFieldPlaceholder,
  selectFieldName,
  autocompliteData,
  autocompliteTextName,
  showLeftAction,
  autocomplitePlaceholder,
}) => {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isShowSearchField, setIsShowSearchField] = useState(false);
  const [currentSelectValue, setCurrentSelectValue] = useState("placeholder");

  const handleShowFilter = () => setIsShowFilter((prevState) => !prevState);
  const handleShowSeachField = () =>
    setIsShowSearchField((prevState) => !prevState);

  return (
    <>
      {!isShowFilter ? (
        <Grid sx={{ marginBottom: "4rem" }} container>
          <Grid item xs={6}>
            {!showLeftAction && (
              <>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginRight: "3.5rem",
                    height: "4.7rem",
                    fontFamily: "Baguede",
                  }}
                  onClick={handleShowFilter}
                >
                  <FilterIcon />
                </Box>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "end",
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                textAlign: "end",
                alignItems: "center",
                justifyContent: "end",
                height: "4.7rem",
              }}
            >
              {!isShowSearchField ? (
                <Box onClick={handleShowSeachField}>
                  <SearchIconSvg />
                </Box>
              ) : (
                <OutlinedInput
                  sx={{
                    minWidth: "34.7rem",
                    "& .MuiOutlinedInput-input": {
                      fontSize: "1.8rem",
                      fontFamily: "Baguede",
                    },
                  }}
                  onChange={(event) => {
                    handleFilters(textFieldName, event);
                  }}
                  placeholder={textFieldPlaceholder}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          fontSize: "1.8rem",
                          fontFamily: "Baguede",
                        },
                      }}
                    >
                      <IconButton
                        onClick={() => setIsShowSearchField(false)}
                        edge="end"
                      >
                        <SearchIconSvg stroke="#DD853E" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid sx={{ marginBottom: "4rem" }} container>
          <Grid item xs={10}>
            <Box
              sx={{
                display: "flex",
                gap: "2rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "3.5rem",
                }}
                onClick={handleShowFilter}
              >
                <FilterIcon fill="#DD853E" />
              </Box>
              {!showLeftAction && (
                <>
                  <Autocomplete
                    disablePortal
                    options={autocompliteData}
                      sx={{
                      fontSize: "1.8rem",
                      width: purchaseTypeOptions.length === 0 ? 350 : 240,
                      "& .MuiAutocomplete-root": {
                        fontSize: "1.8rem",
                        fontFamily: "Baguede",
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        display: "none",
                        fontFamily: "Baguede",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{ fontFamily: "Baguede" }}
                        placeholder={autocomplitePlaceholder}
                        sx={{
                          "& .MuiInputBase-input": {
                            fontSize: "1.8rem",
                            fontFamily: "Baguede",
                          },
                        }}
                        {...params}
                      />
                    )}
                    onSelect={(event) => {
                      handleFilters(autocompliteTextName, event);
                    }}
                  />
                  <Select
                    sx={{
                      width: purchaseTypeOptions.length === 0 ? 350 : 240,
                      "& .MuiSelect-select": {
                        alignItems: "center",
                        display: "flex",
                        fontFamily: "Baguede",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "1.8rem",
                        fontFamily: "Baguede",
                      },
                      color:
                        currentSelectValue === "placeholder" ? "#A1A1A1" : "",
                    }}
                    defaultValue={selectOptions[0].value}
                    onChange={(event) => {
                      setCurrentSelectValue(event?.target?.value);
                      handleFilters(selectFieldName, event);
                    }}
                  >
                    {selectOptions.map(({ label, value }) => (
                      <MenuItem
                        style={{ fontFamily: "Baguede" }}
                        key={`${label}_${value}`}
                        sx={{
                          fontSize: "1.8rem",
                        }}
                        value={value}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                  {purchaseTypeOptions?.length > 0 && (
                    <Select
                      sx={{
                        width: 240,
                        "& .MuiSelect-select": {
                          alignItems: "center",
                          display: "flex",
                          fontFamily: "Baguede",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "1.8rem",
                          fontFamily: "Baguede",
                        },
                        color:
                          currentSelectValue === "placeholder" ? "#A1A1A1" : "",
                      }}
                      defaultValue={purchaseTypeOptions[0].value}
                      onChange={(event) => {
                        setCurrentSelectValue(event?.target?.value);
                        handleFilters(selectFieldName, event);
                      }}
                    >
                      {purchaseTypeOptions.map(({ label, value }) => (
                        <MenuItem
                          style={{ fontFamily: "Baguede" }}
                          key={`${label}_${value}`}
                          sx={{
                            fontSize: "1.8rem",
                          }}
                          value={value}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <OutlinedInput
                onChange={(event) => {
                  handleFilters(textFieldName, event);
                }}
                sx={{
                  fontSize: "1.8rem",
                }}
                placeholder={textFieldPlaceholder}
                fullWidth
                endAdornment={
                  <InputAdornment
                    position="end"
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        fontSize: "1.8rem",
                      },
                    }}
                  >
                    <IconButton edge="end">
                      <SearchIconSvg stroke="#DD853E" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default TableFilter;
