import AddIcon from '../../../assets/icons/addIcon.png';
import './StylesAddFieldButton.scss';

const AddFieldButton = ({ onClick, text, type }) => (
  <div className='AddFieldButton__wrapper'>
    <button type={type} onClick={onClick} className='AddFieldButton'>
      {text}
      <img src={AddIcon} alt='add' />
    </button>
  </div>
)

export default AddFieldButton;
