import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { Typography, Box, TableRow } from '@mui/material';

import { getUserPasses, removeStreamPass } from '../../api/passes';
import { convertDate } from '../../utils/convertDate';
import { LIMIT, STREAM_PASS_TYPE } from '../../constants';
import Table from '../table/Table';
import Modal from '../common/modal/Modal';
import ActionList from '../actionsList/ActionList';
import Button from '../common/button/Button';
import ticketIcon from '../../assets/icons/ticketIcon.png';
import ellipsisIcon from '../../assets/icons/ellipsis.png';
import ticketsIcon from '../../assets/icons/ticketsIcon.png';
import viewOrder from '../../assets/icons/viewOrderIcon.png';
import SendStreamPassModal from '../modals/sendStreamPass/SendStreamPassModal';
import CustomTabelCell from '../common/CustomTebleCell/CustomTableCell';
import TransactionInformation from "../transactionInformation/TransactionInformation";
import {getTransactionById} from "../../utils/getTransactionById";
import moment from "moment";

// const streamPassDataTransorm = (streamPasses) => {
//   if (Array.isArray(streamPasses) && streamPasses.length) {
//     return streamPasses.map((streamPass) => ({
//       id: streamPass?.id,
//       title: streamPass?.eventTitle,
//       type: STREAM_PASS_TYPE[streamPass?.streamPassType],
//       orderNo: streamPass?.orderNo,
//       purchasedOn: convertDate(streamPass?.purchasedOn, '-'),
//       eventDate: convertDate(streamPass?.eventDate, '-'),
//     }))
//   }

//   return [];
// }

const StreamPasses = () => {
  const [isShowModalRemoveStreamPass, setIsShowModalRemoveStreamPass] = useState(false);
  const [isShowSendStreamPassModal, setIsShowStreamPassModal] = useState(false);
  const [currentStreamPass, setCurrentStreamPass] = useState(null);
  const [isShowTransactionInfo, setIsShowTransactionInfo] = useState(false);
  const [streamPassOrder, setStreamPassOrder] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });

  const {
    isError,
    isLoading,
    data,
    error,
    mutate,
  } = useMutation(({ limit, page, userId }) => getUserPasses({ limit, page, userId }));

  const {
    isSuccess: isSuccessRemoveStreamPass,
    isError: isErrorRemoveStreamPass,
    isLoading: isLoadingRemoveStreamPass,
    data: dataRemoveStreamPass,
    mutate: mutateRemoveStreamPass,
  } = useMutation(({ id }) => removeStreamPass({ id }));

  const handleCloseRemoveStreamPassModal = () => {
    setIsShowModalRemoveStreamPass(() => false);
  }
  const handleCloseSendStreamPassModal = () => {
    setIsShowStreamPassModal(() => false);
  }

  const handleOpenRemoveStreamPassModal = (id) => {
    setCurrentStreamPass(() => id);
    setIsShowModalRemoveStreamPass(() => true);
  };

  const hadndleOpenSendStreamPassModal = (id) => {
    setCurrentStreamPass(() => id);
    setIsShowStreamPassModal(() => true)
  }

  const handleRemoveStreamPass = (id) => mutateRemoveStreamPass({ id })

  const handleCloseTransactionInfo = () => setIsShowTransactionInfo(() => false);

  const handleShowTransactionInfo = (item = {}) => {
    const {event, orderItem, pass} = item
      setStreamPassOrder({event, orderItem, pass});
      setIsShowTransactionInfo(() => true);
  };

  useEffect(() => {
    if (!isLoadingRemoveStreamPass && !isErrorRemoveStreamPass && isSuccessRemoveStreamPass) {
      enqueueSnackbar('Stream pass delete successfully', { variant: 'success' });
      setIsShowModalRemoveStreamPass(() => false);
    }

    if (!isLoadingRemoveStreamPass && isErrorRemoveStreamPass) {
      enqueueSnackbar('Having trouble deleting stream pass', { variant: 'error' });
      setIsShowModalRemoveStreamPass(() => false);
    }

  }, [isErrorRemoveStreamPass, isSuccessRemoveStreamPass])

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const id = params.get('userId');

    if (id) {
      mutate({ 
        limit: LIMIT, 
        page: pagination.pageIndex, 
        userId: id,
      });
    }
  }, [pagination.pageIndex]) 

  const tableHeads = [
    { label: 'Event Title' },
    { label: 'Stream Pass Type' },
    { label: 'Order No' },
    { label: 'Purchased On' },
    { label: 'Event Date' },
    { label: ellipsisIcon, isIcon: true },
  ];

  return (
    <Box>
      <Table
        pagination={pagination}
        setPagination={setPagination}
        page={pagination?.pageIndex}
        meta={data?.meta}
        headers={tableHeads}
        error={error}
        tableType='Users'
      >
        {data?.data.map((tableItem, index) => {
          return (
            <TableRow
                key={index}
              sx={{
                background: '#FAFAFA 0% 0% no-repeat padding-box',
                borderBottom: '7px solid white'
              }}
            >
              <CustomTabelCell>
                {tableItem?.event?.title || '-'}
              </CustomTabelCell>
              <CustomTabelCell>
                {tableItem?.pass?.type || '-'}
              </CustomTabelCell>
              <CustomTabelCell>
                {tableItem?.orderItem?.order?.id || '-'}
              </CustomTabelCell>
              <CustomTabelCell>
                {tableItem?.purchasedOn ? moment(tableItem?.purchasedOn).format('DD-MM-YYYY') : '-'}
              </CustomTabelCell>
              <CustomTabelCell>
                {tableItem?.eventDate ? moment(tableItem?.eventDate).format('DD-MM-YYYY') : '-'}
              </CustomTabelCell>
              <CustomTabelCell>
              <ActionList 
                actions={[
                  { label: 'Remove Stream Pass', icon: ticketIcon, action: () => handleOpenRemoveStreamPassModal(tableItem?.id) },
                  { label: 'Send Stream Pass', icon: ticketsIcon, action: () => hadndleOpenSendStreamPassModal(tableItem?.orderItem?.id) },
                  { label: 'View Order', icon: viewOrder, action: () => handleShowTransactionInfo(tableItem) },
                ]}
              />
              </CustomTabelCell>
            </TableRow>
          )
        })}
      </Table>
      {
        isShowSendStreamPassModal &&
          <SendStreamPassModal
            handleClose={handleCloseSendStreamPassModal}
            id={currentStreamPass}
          />
      }
      {
        isShowModalRemoveStreamPass &&
          <Modal
            onClose={handleCloseRemoveStreamPassModal}
            title='Remove user Access'
            action='Yes, Remove'
          >
            <Box 
              sx={{
                textAlign: 'center', 
                gap: '2.5rem', 
                display: 'flex', 
                flexDirection: 'column' 
              }}
            >
              <Typography variant='h5' gutterBottom>
                Are you sure you want to remove the stream pass from this User`s account
              </Typography>
              <Button
                onClick={() => handleRemoveStreamPass({ currentStreamPass })}
                isModal
                isLoading={isLoadingRemoveStreamPass}
              >
                Yes, Remove
              </Button>
            </Box>
          </Modal>
      }
      {
          isShowTransactionInfo &&
          <TransactionInformation
              onClose={handleCloseTransactionInfo}
              streamPassOrder={streamPassOrder}
              selectTransaction={[]}
          />
      }
    </Box>
  )
}

export default StreamPasses;