import {Navigate, Outlet} from 'react-router-dom';
import SideBarMenu from '../../components/sideBarMenu/SideBarMenu';
import SideBarMenuBottomActions from '../../components/sideBarMenu/SideBarMenuBottomActions/SideBarMenuBottomActions';
import logo from '../../assets/icons/balckLogo.svg';
import './StyledDashboardLayout.scss';
// import {useContext} from "react";
// import {AuthContext} from "../../AuthContext";

const DashboardLayout = () => {
    // const {isAuth} = useContext(AuthContext);

    const isAuth = localStorage.getItem('user');

    if(!isAuth) {
        return <Navigate to="/login" state={{ path: '/login' }} />
    }

    return (
        <div className='DashboardLayout'>
            <nav className='DashboardLayout__sidebar'>
                <div className='DashboardLayout__wrapper--logo'>
                    <img src={logo} alt='logo'/>
                </div>
                <SideBarMenu/>
                <SideBarMenuBottomActions/>
            </nav>
            <Outlet />

        </div>
    )
}

export default DashboardLayout;