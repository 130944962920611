import { Typography } from '@mui/material';
import closeIcon from '../../../assets/icons/closeIcon.png';
import './StylesModal.scss';

const Modal = ({ title, children, action, onClose }) => (
  <div className='Modal'>
    <div className='blackout'/>
    <div className='Modal__content'>
      <div className='Modal__content__title'>
        <Typography variant='h4' gutterBottom>
          {title}
        </Typography>
        <button onClick={onClose} className='Modal__content__title__close--button'>
          <img src={closeIcon} alt='closeIcon'/>
        </button>
      </div>
      <div className='Modal__content__main'>
        {children}
      </div>
    </div>
  </div>
)

export default Modal;