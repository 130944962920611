import {
  Select,
  MenuItem,
  FormControl,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { useMutation } from "react-query";
import { useState, useEffect } from "react";
import { enqueueSnackbar } from "notistack";

import { deleteUser, blockUser } from "../../api/user";
import Button from "../../components/common/button/Button";
import FormWrapper from "../common/formWrapper/FormWrapper";
import "./StylesManagement.scss";

const Management = () => {
  const [userId, setUserId] = useState(null);
  const [deleteReason, setDeleteReason] = useState("");
  const [blockReason, setBlockReason] = useState("");
  const [adminPassword, setAdminPassword] = useState("");

  const {
    isLoading: isLoadingUserDelete,
    isSuccess: isSuccessUserDelete,
    isError: isErrorUserDelete,
    mutate: mutateUserDelete,
    error: errorDeleteUser,
  } = useMutation(({ reason, adminPassword }) =>
    deleteUser({ adminPassword, reason, userId })
  );

  const {
    isLoading: isLoadingUserBlock,
    isError: isErrorUserBlock,
    mutate: mutateUserBlock,
    data: dataUserBlock,
    isSuccess: isSuccessUserBlock,
    error: errorUserBlock,
  } = useMutation(({ reason, adminPassword }) =>
    blockUser({ adminPassword, reason, userId })
  );

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const id = params.get("userId");

    if (id) {
      setUserId(id);
    }
  }, []);

  const handleDeleteUser = () =>
    mutateUserDelete({ reason: deleteReason, adminPassword: adminPassword });
  const handleBlockUser = () =>
    mutateUserBlock({ reason: blockReason, adminPassword: adminPassword });
  const handleChangeAdminPassword = (e) => setAdminPassword(e.target.value);
  const handleChangeDeleteReason = (e) => setDeleteReason(e.target.value);
  const handleBlockReason = (e) => setBlockReason(e.target.value);

  useEffect(() => {
    if (!isLoadingUserDelete && isErrorUserDelete) {
      const erorrData = errorDeleteUser?.response?.data?.data;
      erorrData?.forEach(({ field, message }) =>
        enqueueSnackbar(`${field ? field + ":" : ""}${message}`, {
          variant: "error",
        })
      );
    }

    if (!isLoadingUserDelete && !isErrorUserDelete && isSuccessUserDelete) {
      enqueueSnackbar(`User Deleted`, { variant: "success" });
    }

    if (!isLoadingUserBlock && isErrorUserBlock) {
      const erorrData = errorUserBlock?.response?.data?.data;
      erorrData?.forEach(({ field, message }) =>
        enqueueSnackbar(`${field ? field + ":" : ""}${message}`, {
          variant: "error",
        })
      );
    }

    if (!isLoadingUserBlock && !isErrorUserBlock && isSuccessUserBlock) {
      const erorrData = errorDeleteUser?.response?.data?.data;
      enqueueSnackbar(`User Deleted`, { variant: "success" });
    }
  }, [
    isLoadingUserBlock,
    isErrorUserBlock,
    isErrorUserDelete,
    isLoadingUserDelete,
  ]);

  return (
    <FormWrapper>
      <FormControl
        fullWidth
        sx={{
          "& .MuiTextField-root": { m: 1, width: "40rem" },
        }}
      >
        <Box
          sx={{
            marginBottom: "5rem ",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              marginLeft: ".8rem",
            }}
          >
            Password
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10rem",
            }}
          >
            <Box>
              <label className="label">User Password</label>
              <TextField
                type="password"
                onChange={handleChangeAdminPassword}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "1.8rem",
                  },
                }}
              />
            </Box>
            <Button>Send User Reset Password</Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "5rem",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              marginLeft: ".8rem",
            }}
          >
            Block user Account
          </Typography>
          <Box>
            <label className="label">Select Reason</label>
            <Select
              onChange={handleBlockReason}
              sx={{
                margin: ".8rem",
                width: "40rem",
                fontSize: "1.8rem",
              }}
            >
              <MenuItem value={"broke_community_guidelines"}>
                Broke Community Guidelines
              </MenuItem>
              <MenuItem value={"fraudulent_transactions"}>
                Fraudulent Transactions
              </MenuItem>
              <MenuItem value={"suspicious_activity"}>
                Suspicious Activity
              </MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label className="label">Enter Admin Password</label>
              <TextField
                type="password"
                onChange={handleChangeAdminPassword}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "1.8rem",
                  },
                }}
              />
            </Box>
            <Button onClick={handleBlockUser} isLoading={isLoadingUserBlock}>
              Deactivate User
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              marginLeft: ".8rem",
            }}
          >
            Delete user Account
          </Typography>
          <Box>
            <label className="label">Select Reason</label>
            <Select
              onChange={handleChangeDeleteReason}
              sx={{
                margin: ".8rem",
                width: "40rem",
                fontSize: "1.8rem",
              }}
            >
              <MenuItem value={"broke_community_guidelines"}>
                Broke Community Guidelines
              </MenuItem>
              <MenuItem value={"fraudulent_transactions"}>
                Fraudulent Transactions
              </MenuItem>
              <MenuItem value={"suspicious_activity"}>
                Suspicious Activity
              </MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10rem",
            }}
          >
            <Box>
              <label className="label">Enter Admin Password</label>
              <TextField
                type="password"
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "1.8rem",
                  },
                }}
                onChange={handleChangeAdminPassword}
              />
            </Box>
            <Button isLoading={isLoadingUserDelete} onClick={handleDeleteUser}>
              Delete User
            </Button>
          </Box>
        </Box>
      </FormControl>
    </FormWrapper>
  );
};

export default Management;
