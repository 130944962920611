import { Box } from "@mui/material";
import { Field, useFormikContext, FieldArray } from "formik";
import Input from "../../../components/common/input/Input";
import UploadEventPrint from "../../../components/common/uploadEventPrint/UploadEventPrint";
import "./StylesCurrencyInformationForm.scss";
import AddFieldButton from "../../../components/common/addFieldButton/AddFieldButton";
import { useState } from "react";
const CurrencyInformationForm = ({
  tmpPreview,
  setTmpPreview,
  sprayMoneyCurrencyImage,
  sprayMoneyTopSenderIcon,
  errors,
  touched,
}) => {
  const {
    values: { sprayMoneyImageUrls },
  } = useFormikContext();

  const [initSprayMoneyImageUrls, setInitSprayMoneyImageUrls] = useState(() => {
    if (sprayMoneyImageUrls?.length) {
      if (sprayMoneyImageUrls?.length === 1) {
        return [...sprayMoneyImageUrls, ""];
      }
      return [...sprayMoneyImageUrls];
    }
    return [{}];
  });

  return (
    <div className="StylesCurrencyInformationForm">
      <Field
        component={Input}
        label="Currency Name. *"
        name="sprayMoneyCurrencyName"
        error={touched.sprayMoneyCurrencyName && errors?.sprayMoneyCurrencyName}
        helperText={errors?.sprayMoneyCurrencyName}
      />
      <FieldArray
        name="sprayMoneyImageUrls"
        render={(arrayHelpers) => (
          <Box>
            {initSprayMoneyImageUrls.map((item, index) => (
              <Box sx={{ marginBottom: "8px" }}>
                <UploadEventPrint
                  key={index}
                  thumbLabel="Currency Image File"
                  type="horizontal"
                  subText="Upload SVG file only"
                  tmpPreview={tmpPreview}
                  index={index}
                  blockName="sprayMoneyImageUrls"
                  setTmpPreview={setTmpPreview}
                  arrayHelpers={arrayHelpers}
                  fieldName={`sprayMoneyImageUrls${index}`}
                  item={item}
                />
              </Box>
            ))}
            <AddFieldButton
              type="button"
              text="Add Currency Image"
              onClick={() => {
                arrayHelpers.push({ imgContent: "", imgType: "" });
                setInitSprayMoneyImageUrls((prevState) => {
                  return [...prevState, ""];
                });
              }}
            ></AddFieldButton>
          </Box>
        )}
      />

      <Field
        component={Input}
        label="Top Spender Title"
        name="sprayMoneyTopSenderTitle"
        blockName="sprayMoney"
      />
      <UploadEventPrint
        thumbLabel="Top Spender Icon"
        type="horizontal"
        subText="Upload SVG file only"
        blockName="sprayMoney"
        fieldName="sprayMoneyTopSenderIcon"
        tmpPreview={tmpPreview}
        setTmpPreview={setTmpPreview}
        item={sprayMoneyTopSenderIcon}
      />
    </div>
  );
};
export default CurrencyInformationForm;
