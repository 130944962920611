import { useState, useEffect } from 'react';

import { convertRegisterStatusForTable } from '../../utils/сonvertRegisterStatusForTable';
import TransactionInformationList from './transactionInformationList/TransactionInformationList';
import closeIcon from '../../assets/icons/closeIcon.png';
import './StylesTransactionInformation.scss';

const TransactionInformation = ({ onClose, selectTransaction, streamPassOrder }) => {
  const [currentTransaction, setCurrectTransaction] = useState([selectTransaction]);
  const [transactionInfo, setTransactionInfo] = useState([]);
  useEffect(() => {
    if (currentTransaction.length) {
      const result = currentTransaction.reduce((acc, currentValue) => {
        const tmpStreamPassType = [];

        currentValue?.orderItem?.forEach((pass, index) => {
          if (pass?.pass?.type === 'LIVESTREAM' ) tmpStreamPassType.push(`- ${index + 1} x Livestream`);
          if (pass?.pass?.type === 'BACKSTAGE' ) tmpStreamPassType.push(`- ${index + 1} x Backstage`);
        })

        acc = {
          ...acc,
          transaction: currentValue.transaction || {},
          streamPasses: {
            steamPassesType: tmpStreamPassType
          }
        }
        return acc;
      }, {
        transaction: {},
        streamPasses: {},
      });

      setTransactionInfo((prevState) => {
        return [
          result
        ]
      })

    }


  
    return () => setCurrectTransaction([]);
  }, [selectTransaction])
   
    
  return (
    <div className='TransactionInformation__wrapper'>
      <div className='TransactionInformation'>
        <div className='TransactionInformation__top'>
        <span>
          Transaction Information
        </span>
          <button onClick={onClose}>
            <img src={closeIcon} alt='close'/>
          </button>
        </div>
        <div className='TransactionInformation__content'>
          <TransactionInformationList
            transactionInfo={transactionInfo}
            streamPassOrder={streamPassOrder}
            selectTransaction={selectTransaction}
          />
        </div>
      </div>
    </div>
  )
}

export default TransactionInformation;