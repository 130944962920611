export const getYearsList = (from, to) => {
  const currentYear = to || new Date().getFullYear();
  let startYear = from || 1940;
  let years = [];

  while(startYear <= currentYear) {
    years.push({
      label: startYear, value: startYear
    })
    startYear ++;
  }

  return years;
}