import { Formik } from "formik"
import { useMutation } from "react-query"
import { useState } from "react"
import { enqueueSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"

import { transactionValidate } from "../../validates/transactionValidation"
import { createOrder } from "../../api/orders/index"
import Page from "../../components/common/page/Page"
import FormWrapper from "../../components/common/formWrapper/FormWrapper"
import CreateStreamPassTransactionForm from "../../forms/transactionForms/CreateStreamPassTransactionForm"

const CreateTransaction = () => {
  const [isNotify, setIsNotify] = useState(false)
  const navigator = useNavigate()
  const { isLoading, mutate } = useMutation(({ sendData }) =>
    createOrder({ sendData, notify: isNotify })
  )

  const createDataForSend = values => {
    return {
      userId: values?.user,
      reason: values?.reason,
      orderItem: [
        {
          passId: values?.streamPassType,
          quantity: values?.enterQuantity,
        },
      ],
    }
  }

  const handleSubmit = values => {
    const sendData = createDataForSend(values)

    mutate(
      { sendData },
      {
        onSuccess: () => {
          enqueueSnackbar("Transaction create", { variant: "success" })
          navigator("/transactions")
        },
        onError: error => {
          const errorData = error?.response?.data?.data

          errorData?.forEach(({ field, message }) =>
            enqueueSnackbar(`${field ? field + ":" : ""}${message}`, {
              variant: "error",
            })
          )
        },
      }
    )
  }

  return (
    <Page title="Create Transaction">
      <FormWrapper>
        <Formik
          initialValues={{
            user: "",
            streamPassType: "",
            reason: "",
            liveStream: "",
            enterQuantity: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={transactionValidate}
        >
          {({ errors, touched }) => {
            return (
              <CreateStreamPassTransactionForm
                setIsNotify={setIsNotify}
                touched={touched}
                errors={errors}
                isSaveLoading={isLoading}
                navigator={navigator}
              />
            )
          }}
        </Formik>
      </FormWrapper>
    </Page>
  )
}

export default CreateTransaction
