import * as Yup from "yup";

export const emailValidate = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  type: Yup.string().required("This field is required!"),
  subject: Yup.string().required("This field is required!"),
  replyTo:Yup.string().required("This field is required!"),
  sendOnDate: Yup.string().when("sendImmediate", {
    is: false,
    then: () => Yup.string().required("This field is required!"),
    otherwise: () => Yup.string().strip(),
  }),
  sendOnTime: Yup.string().when("sendImmediate", {
    is: false,
    then: () => Yup.string().required("This field is required!"),
    otherwise: () => Yup.string().strip(),
  }),
});
