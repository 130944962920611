import * as Yup from 'yup';

export const userWalletValidate = Yup.object().shape({
  enterAmount: Yup.number()
    .min(10, 'Minimum transaction 10')
    .max(50000, 'Maximum transaction 50000')
    .required('This field in required!'),
  chooseReason: Yup.string()
    .required('This field in required!'),
  enterPassword: Yup.string()
    .required('This field in required!'),
});