import moment from "moment";
import "./TransactionInformationListItem.scss";
import { convertRegisterStatusForTable } from "../../../utils/сonvertRegisterStatusForTable";
import { useEffect } from "react";

const TransactionInformationListItem = ({
  order,
  streamPassOrder,
  selectTransaction,
  transactionInfo
}) => {
  let { transaction, streamPasses } = order;
  const { type } = selectTransaction?.orderItem[0];
  if (
    streamPassOrder?.orderItem?.order?.transaction &&
    (!transaction || !Object.keys(transaction).length)
  ) {
    transaction = streamPassOrder?.orderItem?.order?.transaction;
  }

  let {
    paymentServiceOrderNumber,
    paymentGateway,
    date,
    paymentServiceReferenceNumber,
    feeBreakdownTotal,
    feeBreakdownSubtotal,
    feeBreakdownTax,
    feeBreakdownFee,
    status,
  } = transaction;
  

  status = convertRegisterStatusForTable(status);
  

  const eventName = streamPasses?.eventName || streamPassOrder?.event?.title;
  const streamPassType = streamPassOrder ? (
    <li>{`1 x ${streamPassOrder?.pass?.type?.toLowerCase()}`}</li>
  ) : (
    streamPasses?.steamPassesType.map((item) => <li>{item || "-"}</li>)
  );


  return (
    <li className="TransactionInformationListItem">
      <div className="TransactionInformationListItem__item">
        <span className="TransactionInformationListItem__item--title">
          Payment Service
        </span>
        <ul>
          <li className="TransactionInformationListItem__item--content">
            Order number: {paymentServiceOrderNumber || "-"}
          </li>
          <li className="TransactionInformationListItem__item--content">
            Payment Gateway: {paymentGateway || "-"}
          </li>
          <li className="TransactionInformationListItem__item--content">
            Transaction Date: {date ? moment(date).format("DD-MM-YYYY") : "-"}
          </li>
          <li className="TransactionInformationListItem__item--content">
            TimeStamp: {date ? moment(date).format("DD-MM-YYYY") : "-"}
          </li>
          <li className="TransactionInformationListItem__item--content">
            Reference Number: {paymentServiceReferenceNumber || "-"}
          </li>
          {status === "Success" ? (
            <li className="TransactionInformationListItem__item--success">
              Status: <span>{status || "-"}</span>
            </li>
          ) : (
            <li className={`TransactionInformationListItem__item--content`}>
              Status: {status || "-"}
            </li>
          )}
        </ul>
      </div>
      {!(type === "SPRAY_MONEY") && (
        <div className="TransactionInformationListItem__item__center">
          <span className="TransactionInformationListItem__item--title">
            Stream Passes
          </span>
          <ul>
            <li className="TransactionInformationListItem__item--content">
              Event name: {eventName || "-"}
            </li>
            <li className="TransactionInformationListItem__item--content">
              Stream Pass Type: {streamPassType}
            </li>
          </ul>
        </div>
      )}
      <div className="TransactionInformationListItem__item">
        <span className="TransactionInformationListItem__item--title">
          Free Breakdown
        </span>
        <ul>
          <li className="TransactionInformationListItem__item--content">
            Total: {feeBreakdownTotal || "-"}
          </li>
          <li className="TransactionInformationListItem__item--content">
            Subtotal: {feeBreakdownSubtotal || "-"}
          </li>
          <li className="TransactionInformationListItem__item--content">
            Tax: {feeBreakdownTax || "-"}
          </li>
          <li className="TransactionInformationListItem__item--content">
            Fee: {feeBreakdownFee || "-"}
          </li>
        </ul>
      </div>
    </li>
  );
};

export default TransactionInformationListItem;
