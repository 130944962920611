import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack';

import Modal from '../../common/modal/Modal';
import Button from '../../common/button/Button';
import { validatePending } from '../../../api/orders';

const ValidatePendingModal = ({ handleClose, selectTransactionId, refetch }) => {
  const {
    isLoading,
    isError,
    isSuccess,
    mutate,
    error,
    data
  } = useMutation(({ transactionId }) => validatePending({ transactionId }));

  const handleReopen = () => mutate({ transactionId: selectTransactionId });

  useEffect(() => {
    if (!isLoading && !isError && isSuccess) {
      refetch();
      enqueueSnackbar(data?.data?.message, { variant: 'success' });
      handleClose();
    }

    if (!isLoading && isError) {
      const erorrData = error?.response?.data?.data;

      erorrData?.forEach(({ field, message }) => enqueueSnackbar(`${field ? field + ':' : ''}${message}`, { variant: 'error' }));
      handleClose();
    }
  }, [isError, isLoading, isSuccess])


  return (
    <Modal
      title='Validate Pending'
      onClose={handleClose}
    >
    <Box>
      <Typography
          sx={{
            textAlign: 'center'
          }} variant="h5" gutterBottom
        >
        Please confirm that you would like to mark this event as validate pending.
      </Typography>
      <Box sx={{
        textAlign: 'center',
        marginTop: '2rem'
      }}>
        <Button
          isModal
          isLoading={isLoading}
          onClick={handleReopen}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  </Modal>
  )
}

export default ValidatePendingModal;