import { Box } from '@mui/material';

import SideBarMenuUserBottomAction from '../SideBarMenuUserBottomAction/SideBarMenuUserBottomAction';
import './StylesSideBarMenuBottomActions.scss';

const SideBarMenuBottomActions = () => (
  <Box className='SideBar__bottom'>
    <SideBarMenuUserBottomAction />
  </Box>
)

export default SideBarMenuBottomActions;