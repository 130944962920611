import ToggleSwitch from "../../../components/common/toggleSwitch/ToggleSwitch";
import "./StylesStreamPasses.scss";

const StreamPassesBlock = ({
  title,
  children,
  name,
  passIndex,
  passes,
  onDemandPasses,
  fieldName,
}) => {
  return (
    <div className="StreamPassesBlock">
      <div className="StreamPassesBlock__header">
        <span>{title}</span>
        <ToggleSwitch passes={passes} passIndex={passIndex} name={name} onDemandPasses={onDemandPasses} />
      </div>
      <div className="StreamPassesBlock__content">{children}</div>
    </div>
  );
};

export default StreamPassesBlock;
