import { Field, FieldArray } from "formik";
import { Box, Typography } from "@mui/material";

import Button from "../../../components/common/button/Button";
import Input from "../../../components/common/input/Input";
import "./StylesStreamInformationForm.scss";
import UploadEventPrint from "../../../components/common/uploadEventPrint/UploadEventPrint";
import AddFieldButton from "../../../components/common/addFieldButton/AddFieldButton";

const StreamInformationForm = ({
  passIndex,
  passes,
  setTmpPreview,
  tmpPreview,
}) => {
  return (
    <div className="StreamInformationForm">
     
      <div className="StreamInformationForm__form">
        <Field
          component={Input}
          label="What should this stream pass be called"
          name={`passes[${passIndex}].title`}
          defaultValue={passes?.[passIndex]?.title}
        />
        <Field
          component={Input}
          multiline
          rows={3}
          label="Purchase Description"
          name={`passes[${passIndex}].description`}
          defaultValue={passes?.[passIndex]?.description}
        />
        <Field
          component={Input}
          label="We’ll need a stream pass URL"
          name={
            passIndex === 2
              ? `passes[${passIndex}].streamUrl`
              : `passes[${passIndex}].streamUrl`
          }
          defaultValue={passes?.[passIndex]?.streamUrl}
        />
        {(passIndex === 0 || passIndex === 1) && (
          <Field
            component={Input}
            multiline
            rows={8}
            label="Describe this stream pass on the event page"
            name={`passes[${passIndex}].eventPageDescription`}
            defaultValue={passes?.[passIndex]?.eventPageDescription}
          />
        )}
      
        {passIndex === 2 ? (
          <Box>
            <FieldArray
              name={`passes[${passIndex}].videoContent`}
              render={(arrayHelpers) => {
                return (
                  <Box>
                    <Box>
                      {passes &&
                        passes[passIndex]?.videoContent?.map((item, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                marginTop: "3rem",
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                              }}
                            >
                              <Field
                                component={Input}
                                label={`Video Title ${index + 1} (Event Page)`}
                                name={`passes[${passIndex}].videoContent[${index}].videoTitle`}
                                defaultValue={passes?.[passIndex]?.streamUrl}
                              />
                              <Field
                                component={Input}
                                label="Add video Description (Event Page)"
                                multiline
                                rows={8}
                                name={`passes[${passIndex}].videoContent[${index}].videoDescription`}
                                defaultValue={
                                  passes?.[passIndex]?.videoDescription
                                }
                              />
                              <Field
                                component={Input}
                                label="VIdeo URL"
                                name={`passes[${passIndex}].videoContent[${index}].videoUrl`}
                                defaultValue={passes?.[passIndex]?.videoUrl}
                              />
                              <UploadEventPrint
                                type="horizontal"
                                label={`Cover Image Video ${index + 1}`}
                                subText="Image will be resized to width: 499px, height: 722px"
                                blockName="streamPassVideoContentUrls"
                                tmpPreview={tmpPreview}
                                setTmpPreview={setTmpPreview}
                                fieldName={`passes[${passIndex}].videoContent[${index}].videoCoverArt`}
                                index={index}
                                item={
                                  passes[passIndex]?.videoContent[index]
                                    .videoCoverArt
                                }
                              />
                            </Box>
                          );
                        })}
                    </Box>
                    <AddFieldButton
                      text="Add Video Field"
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          videoTitle: "",
                          videoDescription: "",
                          videoUrl: "",
                          videoCoverArt: "",
                        })
                      }
                    />
                  </Box>
                );
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default StreamInformationForm;
