import logo from '../../assets/images/pexels-breston-2.png';
import AuthorizationLayout from '../../layouts/AuthorizationLayout';
import ForgotPasswordForm from '../../forms/forgotPasswordForm/ForgotPasswordForm';

const ForgotPasswordPage = () => (
  <AuthorizationLayout img={logo}>
    <ForgotPasswordForm />
  </AuthorizationLayout>
);

export default ForgotPasswordPage;
