import { Box, TableRow } from "@mui/material";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LIMIT } from "../../constants";
import { getUsers } from "../../api/user";
import { getEvents } from "../../api/events";
import { convertDate } from "../../utils/convertDate";
import CustomTabelCell from "../../components/common/CustomTebleCell/CustomTableCell";
import TableFilter from "../../components/tableFilter/TableFIlter";
import useDebounce from "../../hooks/useDebounce";
import Table from "../../components/table/Table";
import ActionList from "../../components/actionsList/ActionList";
import checkCircleIcon from "../../assets/icons/checkCircleIcon.svg";
import errorCircleIcon from "../../assets/icons/errorCircleIcon.svg";
import ellipsisIcon from "../../assets/icons/ellipsis.png";
import editUserIcon from "../../assets/icons/editUserIcon.png";
import emailUserIcon from "../../assets/icons/resendEmailIcon.png";
import viewTransactionIcon from "../../assets/icons/viewTransactionIcon.png";
import assignSteamPassIcon from "../../assets/icons/assignSteamPassIcon.png";
import userManagementIcon from "../../assets/icons/userMeneagemntIcon.png";

const tableHeads = [
  { label: "First Name" },
  { label: "Last Name" },
  { label: "User Email Address" },
  { label: "Country" },
  { label: "Registered" },
  { label: "Status" },
  { label: "S.P’s" },
  { label: ellipsisIcon, isIcon: true },
];

const filterUserStatus = [
  { label: "User Status", value: "placeholder" },
  { label: "Active", value: "ACTIVE" },
  { label: "Blocked", value: "BLOCKED" },
];

const Customers = () => {
  const navigator = useNavigate();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [events, setEvents] = useState([]);
  const [isShowSearchField, setIsShowSearchField] = useState(false);
  const [searchEvent, setSearchEvent] = useState("");
  const [filters, setFilters] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });
  const debouncedFilters = useDebounce(filters, 300);
  const debouncedSearchEvent = useDebounce(searchEvent, 300);

  const { data, refetch, error } = useQuery({
    retry: 0,
    queryKey: ["customers"],
    queryFn: () =>
      getUsers({
        page: pagination?.pageIndex,
        limit: LIMIT,
        status: filters?.status,
        search: filters?.search,
        eventId: filters?.eventId,
      }),
  });

  const { data: eventData, refetch: eventRefetch } = useQuery({
    retry: 0,
    queryKey: ["events"],
    queryFn: () =>
      getEvents({
        page: pagination?.pageIndex,
        limit: LIMIT,
        search: searchEvent,
      }),
  });

  useEffect(() => {
    eventRefetch();
  }, [debouncedSearchEvent]);

  useEffect(() => {
    if (eventData) {
      setEvents(() => {
        return eventData?.data.map((event) => {
          return {
            label: event?.title,
            id: event.id,
          };
        });
      });
    }
  }, [eventData]);

  const handleEditUser = (id) =>
    navigator(`/userInformation/?userId=${id}&tab=contactInfo`);
  const handleEmailUser = (id) =>
    navigator(`/userInformation/?userId=${id}&tab=emails`);
  const handleViewTransaction = (id) =>
    navigator(`/userInformation/?userId=${id}&tab=transactions`);
  const handleUserManagement = (id) =>
    navigator(`/userInformation/?userId=${id}&tab=management`);
  const handleAssignSreamPass = (id) =>
    navigator(`/userInformation/?userId=${id}&tab=streamPassses`);
  const handleShowFilter = () => setIsShowFilter((prevState) => !prevState);
  const handleShowSeachField = () =>
    setIsShowSearchField((prevState) => !prevState);

  useEffect(() => {
    refetch();
  }, [pagination.pageIndex, debouncedFilters]);

  const handleChangeFilter = (field, event) => {
    const value = event.target.value;

    if (field === "event") {
      setSearchEvent(value);
      const eventId = events?.find(({ label }) => label === value);

      return setFilters((prevState) => {
        return {
          ...prevState,
          ["eventId"]: eventId?.id,
        };
      });
    }

    setFilters((prevState) => {
      return {
        ...prevState,
        [field]: value === "placeholder" ? "" : value,
      };
    });
  };

  return (
    <Box>
      <TableFilter
        autocompliteData={events}
        autocompliteTextName="event"
        autocomplitePlaceholder="Livestream Title"
        textFieldName="search"
        selectFieldName="status"
        handleFilters={handleChangeFilter}
        selectOptions={filterUserStatus}
        textFieldPlaceholder="Search for Customer"
        handleShowFilter={handleShowFilter}
        isShowFilter={isShowFilter}
        handleShowSeachField={handleShowSeachField}
        isShowSearchField={isShowSearchField}
      />
      <Table
        pagination={pagination}
        setPagination={setPagination}
        page={pagination?.pageIndex}
        meta={data?.meta}
        headers={tableHeads}
        error={error}
        tableType="Users"
      >
        {data?.data.map((cusomer) => {
          return (
            <TableRow
              key={cusomer?.id}
              sx={{
                background: "#FAFAFA 0% 0% no-repeat padding-box",
                borderBottom: "7px solid white",
              }}
            >
              <CustomTabelCell>{cusomer?.firstName}</CustomTabelCell>
              <CustomTabelCell>{cusomer?.lastName}</CustomTabelCell>
              <CustomTabelCell>{cusomer?.email}</CustomTabelCell>
              <CustomTabelCell>{cusomer?.countryCode}</CustomTabelCell>
              <CustomTabelCell>
                {convertDate(cusomer?.createdAt, "-")}
              </CustomTabelCell>
              <CustomTabelCell>
                <Box
                  sx={{
                    position: "relative",
                    maxWidth: "1.7rem",
                    maxHeigth: "1.7rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {cusomer?.status === "ACTIVE" ? (
                    <img
                      style={{
                        position: "absolute",
                        width: "100%",
                      }}
                      src={checkCircleIcon}
                      alt="circle"
                    />
                  ) : (
                    <img
                      style={{
                        position: "absolute",
                        width: "100%",
                      }}
                      src={errorCircleIcon}
                      alt="circle"
                    />
                  )}
                </Box>
              </CustomTabelCell>
              <CustomTabelCell>{cusomer?.passAccess}</CustomTabelCell>
              <CustomTabelCell>
                <ActionList
                  actions={[
                    {
                      label: "Edit User",
                      icon: editUserIcon,
                      action: () => handleEditUser(cusomer?.id),
                    },
                    {
                      label: "Email User",
                      icon: emailUserIcon,
                      action: () => handleEmailUser(cusomer?.id),
                    },
                    {
                      label: "View Transactions",
                      icon: viewTransactionIcon,
                      action: () => handleViewTransaction(cusomer?.id),
                    },
                    {
                      label: "Assign Stream Pass",
                      icon: assignSteamPassIcon,
                      action: () => handleAssignSreamPass(cusomer?.id),
                    },
                    {
                      label: "User Management",
                      icon: userManagementIcon,
                      action: () => handleUserManagement(cusomer?.id),
                    },
                  ]}
                />
              </CustomTabelCell>
            </TableRow>
          );
        })}
      </Table>
    </Box>
  );
};

export default Customers;
