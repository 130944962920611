import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormikContext } from "formik";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const BasicDatePicker = () => {
  const { setFieldValue, values: { sendOnDate } } = useFormikContext();
  const handleChange = (date) => {
    setFieldValue("sendOnDate", date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={sendOnDate ? dayjs(sendOnDate) : null}
        onChange={handleChange}
        renderInput={(params) => <input {...params} />}
      />
    </LocalizationProvider>
  );
};

export default BasicDatePicker;
