import HeadNavBar from "../../components/headNavBar/HeadNavBar";
import ArtistBlock from "../../components/artistBlock/ArtistBlock";
import "./StylesLiveStreamLayout.scss";
import { Grid } from "@mui/material";

const LiveStreamLayout = ({
  children,
  navbarLinks,
  handleChangeActiveHeadMenu,
  activeHeadNavMenuId,
  isDisableChangeTab,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const tab = urlParams.get("tab");
  const isShowArtistBlock = !(tab === "eventContent" || tab === "streamPasses");

  return (
    <div className="LiveStreamLayout">
      <Grid container spacing={3}>
        {isShowArtistBlock && <ArtistBlock />}
        <Grid
          item
          xs={12}
          md={12}
          lg={isShowArtistBlock ? 9 : 12}
          xl={isShowArtistBlock ? 10 : 12}
        >
          <div className="LiveStreamLayout__content">
            <HeadNavBar
              navbarLinks={navbarLinks}
              handleChangeActiveHeadMenu={handleChangeActiveHeadMenu}
              activeHeadNavMenuId={activeHeadNavMenuId}
              isDisableChangeTab={isDisableChangeTab}
            />
            {children}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LiveStreamLayout;
