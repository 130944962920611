
import React, { useState } from "react";
import {
  Autocomplete as MaterialAutocomplete,
  TextField,
  Box,
  MenuItem, // Add this import
} from "@mui/material";
import ionicIcon from "../../../assets/icons/ioni-black.svg";
import "./StylesAutocomplite.scss";

const Autocomplete = ({
  options,
  onChange,
  label,
  name,
  defaultValue,
  error,
  helperText,
  field,
  isEditEmail,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [rotate, setRotate] = useState(false);

  const handleRotate = () => {
    setRotate((prev) => !prev);
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
    handleRotate();
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
    handleRotate();
  };

  return (
    <MaterialAutocomplete
      options={options}
      fullWidth
      name={name}
      freeSolo
      value={isEditEmail && field?.value?.label ? field.value : null}
      defaultValue={defaultValue ? defaultValue : ""}
      onChange={(event, value) => {
        onChange({ label: value?.label, id: value?.id });
        handleDropdownClose();
      }}
      onOpen={handleDropdownOpen}
      onClose={handleDropdownClose}
      renderInput={(params) => (
        <Box className="Autocomplete__renderInput">
          <label>{label}</label>
          <TextField
            error={error}
            name={name}
            sx={{
              width: "100%",
              fontSize: "1.8rem",
              "& .MuiInputBase-input": {
                fontSize: "1.8rem",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "1.1rem",
                marginLeft: 0,
              },
            }}
            helperText={error ? helperText : ""}
            onChange={(e) => {
              const value = e.target.value;
              onChange({ label: value });
            }}
            {...params}
          />
          <div
            className="icon-container"
            onClick={isDropdownOpen ? handleDropdownClose : handleDropdownOpen}
          >
            <img
              className={rotate ? "rotateUp dropIcon" : "rotateDown dropIcon"}
              src={ionicIcon}
              alt="..."
            />
          </div>
        </Box>
      )}
      renderOption={(props, option) => (
        <MenuItem
          style={{ fontFamily: "Baguede", fontSize: "16px" }}
          {...props}
        >
          {option.label}
        </MenuItem>
      )}
    />
  );
};

export default Autocomplete;

