import { createTheme } from "@material-ui/core/styles";
import BaguedeRegular from "./assets/fonts/baguede/Baguede-Regular.ttf";

export const Theme = createTheme({
  typography: {
    fontFamily: "Baguede",
    fontWeight: 200
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: "Baguede",
            src: `url(${BaguedeRegular}) format('truetype')`,
            fontWeight: 600,
            fontStyle: "normal",
          },
        ],
      },
    },
  },
});
