import TransactionInformationListItem from '../TransactionInformationListItem/TransactionInformationListItem';

const TransactionInformationList = ({ transactionInfo, streamPassOrder, selectTransaction }) => {
  return(
  <ul className='TransactionInformationList'>
    {
      transactionInfo && transactionInfo.map((order, idx) =>
        <TransactionInformationListItem
          key={idx}
          order={order}
          streamPassOrder={streamPassOrder}
          selectTransaction={selectTransaction}
          transactionInfo={transactionInfo}
        />
      )
    }
  </ul>
)}

export default TransactionInformationList;