import { fetchClient } from "../axiosinterceptor"

function normalizeEventData(obj) {
  const unusedFields = [
    "startDay",
    "startMonth",
    "startYear",
    "startTime",
    "endDay",
    "endMonth",
    "endYear",
    "endTime",
    "livestreamOnlyPasses",
    "livestreamBackstagePasses",
    "allInclusivePasses",
    "userId",
    "image",
    "printUrlImgType",
    "artistName",
    "sprayMoneyImageUrls",
    "livestreamEnabled",
    "onDemandEnabled",
    "forceDisable",
    "eventVisionSponsor",
  ]

  for (let key in obj) {
    if (
      obj[key] === "" ||
      obj[key] === undefined ||
      obj[key] === null ||
      unusedFields.includes(key)
    ) {
      delete obj[key]
    }

    if (key === "isSprayMoneyEnabled" && !obj[key]) {
      delete obj.sprayMoneyCurrencyName
      delete obj.sprayMoneyCurrencyImage
      delete obj.sprayMoneyTopSenderTitle
      delete obj.sprayMoneyTopSenderIcon
      delete obj.sprayMoneyAllowPaystack
      delete obj.sprayMoneyAllowMTN
      delete obj.sprayMoneyAllowStripe
      delete obj.sprayMoneyImageUrls
    }

    if (key === "passes" || key === "onDemandPasses") {
      obj[key] = obj[key]?.map(pass => {
        if (pass?.isEnabled) {
          const dataObj = {
            id: pass?.id,
            type: pass?.type,
            mode: pass?.mode,
            title: pass?.title,
            allowMTN: pass?.allowMTN,
            streamUrl: pass?.streamUrl,
            isEnabled: pass?.isEnabled,
            allowStripe: pass?.allowStripe,
            description: pass?.description,
            eventPageDescription: pass?.eventPageDescription,
            mtnServiceId: pass?.mtnServiceId,
            passCoverUrl: pass?.passCoverUrl,
            priceGBP: Number(pass?.priceGBP),
            priceNGN: Number(pass?.priceNGN),
            priceUSD: Number(pass?.priceUSD),
            priceMTN: Number(pass?.priceMTN),
            allowPaystack: pass?.allowPaystack,
            enableNGN: Boolean(pass?.enableNGN),
            enableUSD: Boolean(pass?.enableUSD),
            enableGBP: Boolean(pass?.enableGBP),
            enableMTN: Boolean(pass?.enableMTN),
            maxPerUser: Number(pass?.maxPerUser),
          }

          if (pass.type === "ON_DEMAND") {
            dataObj.videoContent = pass?.videoContent
          }

          return dataObj
        }
        return {
          isEnabled: false,
          type: pass?.type,
          mode: pass?.mode,
        }
      })
    }
  }

  return obj
}

export const getEvents = async ({ page, limit, search, artistId, status }) => {
  const { data } = await fetchClient().get(
    `events/limit/${limit}/page/${page}`,
    {
      params: {
        s: search?.length ? search : null,
        [artistId ? "artistId" : ""]: artistId,
        status,
      },
    }
  )

  return data
}

export const editEvent = async values => {
  values = normalizeEventData(values)
  const { passes, onDemandPasses } = values
  let $passes = []
  let $onDemandPasses = []

  if (values?.eventMode === "LIVESTREAM") {
    $onDemandPasses = onDemandPasses.map(odp => ({
      isEnabled: false,
      id: odp?.id,
      type: odp?.type,
      mode: odp?.mode,
    }))
    $passes = passes
  } else if (values?.eventMode === "ON_DEMAND") {
    $passes = passes.map(p => ({
      isEnabled: false,
      id: p?.id,
      type: p?.type,
      mode: p?.mode,
    }))
    $onDemandPasses = onDemandPasses
  }

  const sendData = {
    artistGalleryDescription: values?.artistGalleryDescription,
    artistGalleryUrls: values?.artistGalleryUrls,
    artistId: values?.artistId,
    endAt: values?.endAt,
    onDemandEndAt: values?.onDemandEndAt,
    onDemandEnabled: values?.onDemandEnabled,
    livestreamEnabled: values?.livestreamEnabled,
    eventVisionHeaderDescription: values?.eventVisionHeaderDescription,
    eventVisionHeaderFirst: values?.eventVisionHeaderFirst,
    eventVisionHeaderSecond: values?.eventVisionHeaderSecond,
    eventVisionHeaderUrls: values?.eventVisionHeaderUrls,
    eventVisionSponsors: values?.eventVisionSponsors,
    heroSectionDescription: values?.heroSectionDescription,
    heroSectionTitle: values?.heroSectionTitle,
    heroSectionUrls: values?.heroSectionUrls,
    isSprayMoneyEnabled: values?.isSprayMoneyEnabled,
    location: values?.location,
    musicUrl: values?.musicUrl,
    passes: [...$passes, ...$onDemandPasses],
    rights: values?.rights,
    sprayMoneyAllowMTN: values?.sprayMoneyAllowMTN,
    sprayMoneyAllowPaystack: values?.sprayMoneyAllowPaystack,
    sprayMoneyAllowStripe: values?.sprayMoneyAllowStripe,
    sprayMoneyCurrencyName: values?.sprayMoneyCurrencyName,
    sprayMoneyTopSenderTitle: values?.sprayMoneyTopSenderTitle,
    startAt: values?.startAt,
    onDemandStartAt: values?.onDemandStartAt,
    status: values?.status,
    streamUrl: values?.streamUrl,
    bannerUrl: values?.bannerUrl,
    chatUrl: values?.chatUrl,
    title: values?.title,
    venue: values?.venue,
    vibes: values?.vibes,
    videoCarouselDescription: values?.videoCarouselDescription,
    videoCarouselTitle: values?.videoCarouselTitle,
    videoCarouselUrls: values?.videoCarouselUrls,
    eventMode: values?.eventMode,
  }

  if (values?.sprayMoneyTopSenderIcon) {
    sendData.sprayMoneyTopSenderIcon = values?.sprayMoneyTopSenderIcon
  }

  if (values?.sprayMoneyCurrencyImage) {
    sendData.sprayMoneyCurrencyImage = values?.sprayMoneyCurrencyImage
  }

  if (values?.sprayMoneyImageUrls) {
    sendData.sprayMoneyImageUrls = values?.sprayMoneyImageUrls
  }

  if (values?.printUrlImgType) {
    sendData.printUrlImgType = values?.printUrlImgType
  }

  if (values?.printUrl) {
    sendData.printUrl = values?.printUrl
  }


  const { data } = await fetchClient().put(`events/${values?.eventId}`, {
    ...sendData,
  })
  return data
}

export const createEvent = async values => {
  values = normalizeEventData(values)
  const { passes, onDemandPasses, ...rest } = values
  const payload = {
    ...rest,
    passes: [...passes, ...onDemandPasses],
  }

  const sendData = {
    ...payload,
  }
  
  const { data } = await fetchClient().post("events", {
    ...sendData,
  })

  return data
}

export const deactivateEvent = async ({ eventId }) => {
  const { data } = await fetchClient().put(`events/${eventId}`, {
    status: "DEACTIVATED",
  })

  return data
}

export const getEventById = async ({ eventId }) => {
  const { data } = await fetchClient().get(`events/${eventId}`)

  return data
}
