import axios from "axios";
import { fetchClient } from "../axiosinterceptor";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const logout = async () => {
  localStorage.clear();
  const { data } = fetchClient().get("/auth/logout");

  return data;
};

export const login = async (authData) => {
  if (typeof authData === "string") {
    const json = JSON.parse(authData);
    const { data } = await axios.post(
      `${BASE_URL}/auth/login`,
      {
        email: json?.email,
        password: json?.password,
      },
      {
        withCredentials: true,
      }
    );

    return data;
  }
};

export const resetPassword = async ({ authData, id, token }) => {
  const json = JSON.parse(authData);

  const { data } = await axios.post(
    `${BASE_URL}auth/reset-password/update-password`,
    {
      id,
      token,
      password: json.newPassword,
    }
  );

  return data;
};

export const forgotPassword = async ({ email, redirectUrl }) => {
  const { data } = await axios.post(`${BASE_URL}auth/reset-password/request`, {
    email,
    redirectUrl,
  });

  return data;
};
