import UserInformationBlock from '../../components/userInformarmationBlock/UserInformationBlock';
import HeadNavBar from '../../components/headNavBar/HeadNavBar';
import './StylesCrmLayout.scss';

const CrmLayout = ({
                     navbarLinks,
                     handleChangeActiveHeadMenu,
                     activeHeadNavMenuId,
                     isShowUpdateWalletModal,
                     handleCloseUpdateWalletModal,
                     handleShowUpdateWalletModal,
                     children,
                     imgFieldName,
                     imgType,
                     userData,
                     isNewUser
}) => (
  <div className='CrmLayout'>
    <UserInformationBlock
      isShowUpdateWalletModal={isShowUpdateWalletModal}
      handleCloseUpdateWalletModal={handleCloseUpdateWalletModal}
      handleShowUpdateWalletModal={handleShowUpdateWalletModal}
      imgFieldName={imgFieldName}
      imgType={imgType}
      userData={userData}
    />
    <div className='CrmLayout__content'>
      <HeadNavBar
        isNewUser={isNewUser}
        navbarLinks={navbarLinks}
        handleChangeActiveHeadMenu={handleChangeActiveHeadMenu}
        activeHeadNavMenuId={activeHeadNavMenuId}
      />
      {children}
    </div>
  </div>
)

export default CrmLayout;