import * as Yup from 'yup';

export const transactionValidate = Yup.object().shape({
  user: Yup.string()      
    .required('Field is required'),
  streamPassType: Yup.string()
    .required('This field in required!'),
  reason: Yup.string()
    .required('This field in required!'),
  liveStream: Yup.string()
    .required('This field in required!'),
  enterQuantity: Yup.number()
    .required('This field in required!'),
});