import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchClient = () => {
  const defaultOptions = {
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      authorization: JSON.parse(localStorage.getItem("token"))
    },
  };


  const instance = axios.create(defaultOptions);

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response.status === 401) window.location.href = "/login";
      // localStorage.removeItem("user");
      return Promise.reject(error);
    }
  );
  return instance;
};
