import CountryList from 'country-list-with-dial-code-and-flag';

import Select from '../common/select/Select';

const CountryCodeInput = ({ form, field, label, defaultValue }) => {
  const allCountry = CountryList.getAll();

  const options = allCountry.map((country) => {
    return {
      label: `${country.flag}${country.dial_code}`,
      value: country.dial_code,
    }
  })

  return (
    <Select
      label={label}
      defaultValue={{ value: defaultValue }}
      options={options}
      form={form}
      field={field}
    />
  )
}

export default CountryCodeInput;