import {Alert, Box, Typography} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack';

import { passesShare } from '../../../api/passes';
import { getUsers } from '../../../api/user';
import { LIMIT } from '../../../constants';
import useDebounce from '../../../hooks/useDebounce';
import Autocomplete from '../../common/autocomplete/Autocomplite';
import Button from '../../common/button/Button';
import Modal from '../../common/modal/Modal';

const SendStreamPassModal = ({ handleClose, id }) => {
  const [users, setUsers] = useState([]);
  const [userEmail, setUserEmail] = useState(null);
  const [page, setPage] = useState(1);
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const userDebouncedSearchTerm = useDebounce(userSearchTerm, 300);

  const {
    isLoading: isLoadingUser,
    data: userData,
    refetch: userRefetch,
  } = useQuery({
    queryKey: ['users'],
    queryFn: () => getUsers({ page, limit: LIMIT, search: userSearchTerm }),
    retry: 0,
  });

  const {
    isLoading: isLoadingShare,
    isError: isErrorShare,
    mutate,
    isSuccess: isSuccessShare,
    data,
    error,
  } = useMutation(({ orderItemId, userEmail }) => passesShare({ orderItemId, userEmail }));

  useEffect(() => {
    if (!isLoadingUser && userData?.data) {
      setUsers(() => {
        return userData?.data.map((user) => {
          return {
            ...user,
            label: user?.username,
          }
        })
      })
    }
  }, [isLoadingUser, userData?.data])

  const handleChengeUserAutocomplite = ({ label }) => setUserSearchTerm(label);

  useEffect(() => {
    const selectedUser = users?.find(({ label }) => label === userDebouncedSearchTerm);
    if (selectedUser) {
      setUserEmail(selectedUser?.email)
    }
    userRefetch();
  }, [userDebouncedSearchTerm])

  const handleSubmit = (values, {setErrors}) => {
    mutate({ orderItemId: id, userEmail }, {
      onSuccess: () => {
        enqueueSnackbar('Pass successfully shared', { variant: 'success' });
      },
      onError: (res) => {
        enqueueSnackbar(res?.response?.data?.message, { variant: 'error' });
      }
    })
  }
  console.log('error>', error)
  return (
    <Modal
      title='Send Stream Pass'
      action='Confirm Refund'
      onClose={handleClose}
    >
      <Box>
        <Typography
          sx={{
            textAlign: 'center'
          }} variant="h5" gutterBottom
        >
          Please select a customer
        </Typography>
        <Formik
          initialValues={{
            customer: '',
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <Field
              label='Choose User'
              placeholder='Search by Email'
              name='user'
              component={Autocomplete}
              onChange={handleChengeUserAutocomplite}
              options={users.length ? users : []}
            />
            <Box
                sx={{
                    marginTop: '2rem',
                    textAlign: 'center',
                  }}
            >
              {error?.response?.data?.message && <Alert severity="error">{error?.response?.data?.message}</Alert>}
            </Box>
              <Box sx={{
                marginTop: '2rem',
                textAlign: 'center',
              }}>
                <Button
                  isModal
                  isLoading={isLoadingShare}
                  type='submit'
                >
                  Send Stream Pass
                </Button>
              </Box>
          </Form>
        </Formik>
      </Box>
    </Modal>
  )
}

export default SendStreamPassModal;