import { fetchClient } from '../axiosinterceptor';

export const getAdminGroups = async ({ limit, page, search }) => {
  const { data } = await fetchClient().get(`user-groups/limit/${limit}/page/${page}`, {
    params: {
      s: search,
    }
  });

  return data;
}