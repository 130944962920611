export const getDaysForMonth = (year, month) => {
  let days = [];
  let amountDays = new Date(year, month, 0).getDate();

  while(amountDays >= 1) {
    days.push({
      label: amountDays,
      value: amountDays
    })
    amountDays--;
  }

  return days.reverse();
}