import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./StylesFormWrapper.scss";

const EditorFormWrap = ({ children }) => {
  const location = useLocation();
  const [lock, setLocation] = useState("");

  useEffect(() => {
    setLocation(location.search);
  }, [location.search]);

  return <div className="EditorFormWrap">{children}</div>;
};

export default EditorFormWrap;
