import * as Yup from 'yup';

export const userInformationSchema = Yup.object().shape({
  userName: Yup.string()
    .required('This field in required!'),
  emailAddress: Yup.string()
    .email('Invalid email')
    .required('Required'),
  gender: Yup.string()
    .required('Required'),
  password: Yup.string()
    .required('Required'),
  countryCode: Yup.string()
    .required('Required'),
  phoneNumber: Yup.string()
    .required('Required'),
})