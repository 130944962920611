import { useEffect } from "react";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { Typography, Box } from "@mui/material";

import { markAsComplete } from "../../../api/orders";
import Modal from "../../common/modal/Modal";
import Button from "../../common/button/Button";

const CompeteOrderModal = ({ handleClose, selectTransactionId, refetch }) => {
  const { isLoading, isError, isSuccess, mutate, data, error } = useMutation(
    ({ selectTransactionId }) => markAsComplete({ selectTransactionId })
  );

  const handleComplete = () => mutate({ selectTransactionId });

  useEffect(() => {
    if (!isLoading && !isError && isSuccess) {
      refetch();
      enqueueSnackbar(data?.data?.message, { variant: "success" });
      handleClose();
    }

    if (!isLoading && isError) {
      const erorrData = error?.response?.data?.data;

      erorrData?.forEach(({ field, message }) =>
        enqueueSnackbar(`${field ? field + ":" : ""}${message}`, {
          variant: "error",
        })
      );
      handleClose();
    }
  }, [isError, isLoading, isSuccess]);

  return (
    <Modal title="Complete Order" onClose={handleClose}>
      <Box>
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
          }}
        >
          Please confirm that you would like to mark this transaction order as
          complete
        </Typography>
        <Box
          sx={{
            textAlign: "center",
            marginTop: "4rem",
          }}
        >
          <Button isModal isLoading={isLoading} onClick={handleComplete}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CompeteOrderModal;
