import { useMutation } from 'react-query';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Box, Typography } from '@mui/material';
import { Formik, Field, Form } from 'formik';

import Select from '../../common/select/Select';
import { refundUserPasses } from '../../../api/passes';
import Modal from '../../common/modal/Modal';
import Button from '../../common/button/Button';

const chooseReason = [
  { label: 'Bank Debit Twice', value: 'DUPLICATE' },
  { label: 'Fraudulent Transaction', value: 'FRAUDULENT' },
  { label: 'Incorrect Purchase', value: 'REQUESTED_BY_CUSTOMER' },
];

const ProcessRefundModal = ({ handleCloseProcessRefundModal, selectTransactionId, refetch }) => {
  const { 
    isLoading,
    isError,
    isSuccess,
    mutate,
    error
  } = useMutation(({ id, reason }) => refundUserPasses({ id, reason }));

  const handleSubmit = ({ reason }) => mutate({ id: selectTransactionId, reason });

  useEffect(() => {
    if (!isLoading && isSuccess) {
      refetch();
      enqueueSnackbar('Order refund', { variant: 'success' });
      handleCloseProcessRefundModal();
    }  

    if (!isLoading && isError) {
      const erorrData = error?.response?.data?.data;

      erorrData?.forEach(({ field, message }) => enqueueSnackbar(`${field ? field + ':' : ''}${message}`, { variant: 'error' }));
      handleCloseProcessRefundModal();
    }
  }, [isLoading, isSuccess])

  return (
    <Modal
      onClose={handleCloseProcessRefundModal}
      title='Process Refund'
      action='Confirm Refund'
    >
      <Typography 
        sx={{
          textAlign: 'center'
        }} variant="h5" gutterBottom
      >
        Would you like to refund this transaction to this customer
      </Typography>
      <Formik
        initialValues={{
          reason: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box sx={{
            display: 'flex',
            gap: '2rem'
          }}>
            <Field
              component={Select}
              label='Choose Reason'
              options={chooseReason}
              name='reason'
            />
          </Box>
          <Box sx={{ textAlign: 'center', marginTop: '4rem' }}>
            <Button type='submit' isModal isLoading={isLoading}>
              Confirm Refund
            </Button>
          </Box>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ProcessRefundModal;