import { Field, useFormikContext } from "formik";

import "./StylesToggleSwitch.scss";

const toggle = ({ field, props }) => {
  return (
    <label className="switch">
      {field?.name === "forceDisable" ? (
        <input checked={false} type="checkbox" {...field} {...props} />
      ) : (
        <input checked={field?.value} type="checkbox" {...field} {...props} />
      )}
      <span className="slider round"></span>
    </label>
  );
};

const ToggleSwitch = ({ name, passIndex, passes, onDemandPasses, mode }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Field
      name={name}
      setFieldValue={setFieldValue}
      component={toggle}
      passIndex={passIndex}
      passes={passes}
      onDemandPasses={onDemandPasses}
    />
  );
};
export default ToggleSwitch;
