import AuthorizationLayout from '../../layouts/AuthorizationLayout';
import LoginForm from '../../forms/loginForm/LoginForm';
import logo from '../../assets/images/pexels-breston-.png';

const LoginPage = () => (
  <AuthorizationLayout img={logo}>
    <LoginForm />
  </AuthorizationLayout>
)

export default LoginPage;