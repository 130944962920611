import { useState, useMemo, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Box, TableRow } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { LIMIT } from '../../constants';
import { getAdminGroups } from '../../api/adminsGroups';
import { deleteUserGroup } from '../../api/userGropup';
import Table from '../../components/table/Table';
import useDebounce from '../../hooks/useDebounce';
import Button from '../../components/common/button/Button';
import Page from '../../components/common/page/Page';
import ActionList from '../../components/actionsList/ActionList';
import checkCircleIcon from '../../assets/icons/checkCircleIcon.svg';
import ellipsisIcon from '../../assets/icons/ellipsis.png';
import errorCircleIcon from '../../assets/icons/errorCircleIcon.svg';
import TableFilter from '../../components/tableFilter/TableFIlter';
import CustomTabelCell from '../../components/common/CustomTebleCell/CustomTableCell';

const tableHeads = [
  { label: 'Name' },
  { label: 'Identifier' },
  { label: 'Members' },
  { label: 'Created at' },
  { label: 'Status' },
  { label: ellipsisIcon, isIcon: true },
];

const AdminsGroupsPage = () => {
  const navigator = useNavigate();
  const [filters, setFilters] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });

  const debouncedFilters = useDebounce(filters, 300);

  const {
    data,
    error,
    refetch,
  } = useQuery({
    retry: 0,
    queryKey: ['adminsGroup'],
    queryFn: () => getAdminGroups({ page: pagination.pageIndex, LIMIT, search: filters?.search }),
  });

  const {
    error: errorDelete,
    isLoading: isLoadingDelete,
    mutate: deleteMutate,
    isSuccess: isSuccessDelete,
    data: deleteData,
  } = useMutation(({ id }) => deleteUserGroup({ id }));

  const handleCreateNewAdminGroup = () => navigator('/createEditAdminGroup');
  const handleEditUserGroup = ({ id }) => navigator(`/createEditAdminGroup?groupId=${id}`);
  const handleDeleteGroup = ({ id }) => deleteMutate({ id });

  useEffect(() => {
    const errorData = errorDelete?.response?.data?.message;
    if (errorData) enqueueSnackbar(errorData, { variant: 'error' });
    const successData = deleteData?.message;
    if (successData && isSuccessDelete) enqueueSnackbar(successData, { variant: 'success' });

    if (!isLoadingDelete && isSuccessDelete) refetch();
  }, [isLoadingDelete, errorDelete, debouncedFilters])

  useEffect(() => {
    refetch();
  }, [debouncedFilters])

  const action = useMemo(() => 
    <Button onClick={handleCreateNewAdminGroup}>
      Create Admin Group
    </Button>
  , [])

  const handleChangeFilter = (field, event) => {
    const value = event.target.value;

    setFilters((prevState) => {
      return {
        ...prevState,
        [field]: value,
      }
    })
  }

  return (
    <Page 
      title='Admin Groups'
      action={action}
    >
      <TableFilter 
        handleFilters={handleChangeFilter}
        textFieldName='search'
        showLeftAction
        textFieldPlaceholder='Search for groups'
      />
      <Table
        pagination={pagination}
        setPagination={setPagination}
        page={pagination?.pageIndex}
        meta={data?.meta}
        headers={tableHeads}
        error={error}
      >
        {data?.data.map((tableItem) => {
          return (
            <TableRow
              sx={{
                background: '#FAFAFA 0% 0% no-repeat padding-box',
                borderBottom: '7px solid white'
              }}
            >
              <CustomTabelCell>
                {tableItem?.name}
              </CustomTabelCell>
              <CustomTabelCell>
                {tableItem?.identifier}
              </CustomTabelCell>
              <CustomTabelCell>
                {tableItem?.members}
              </CustomTabelCell>
              <CustomTabelCell>
                {tableItem?.createdAt}
              </CustomTabelCell>
              <CustomTabelCell>
                {
                  tableItem?.status === 'ACTIVE' ? (
                    <Box>
                      <img src={checkCircleIcon} alt='circle'/>
                    </Box>
                  ) : (
                    <Box>
                      <img src={errorCircleIcon} alt='circle'/>
                    </Box>
                  )
                }
              </CustomTabelCell>
              <CustomTabelCell>
              <ActionList
                actions={[
                  { label: 'Delete', icon: '', action: () => handleDeleteGroup({ id: tableItem?.id }) },
                  { label: 'Edit', icon: '', action: () => handleEditUserGroup({ id: tableItem?.id }) }
                ]}
              />
              </CustomTabelCell>
            </TableRow>
            )
          })
        }
      </Table>
    </Page>
  )
}

export default AdminsGroupsPage;