import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import ionicIcon from '../../../assets/icons/ionicIcon.png';
import './StylesSideBarMenuItem.scss';

const SideBarMenuItem = ({ label, url, icon, hasNested, handleChangeActiveTab, activeTab, idx, nested }) => {
  const [activeNestedIndex, setActiveNestedIndex] = useState(-1);

  const handleNestedItemClick = (nestedIdx) => {
    setActiveNestedIndex(nestedIdx === activeNestedIndex ? -1 : nestedIdx);
  };

  

  return (
    <li
      className={activeTab === idx ? 'Sidebar__menuItem active' : 'Sidebar__menuItem'}
      onClick={() => handleChangeActiveTab(idx)}
    >
      <Link to={url}>
        <div className='Sidebar__menuItem__content'>
          <div className='Sidebar__menuItem__content__wrapper'>
            <img className='Sidebar__menuItem__content--icon' src={icon} alt="Icon" />
          </div>
          <span>{label}</span>
          {hasNested && <img src={ionicIcon} className='Sidebar__menuItem__content--icon--arrow' alt='arrow' />}
        </div>
      </Link>
      {hasNested && nested?.length && activeTab === idx && (
        <ul>
          {nested.map((nestedItem, nestedIdx) => (
            <Link to={nestedItem.url} key={nestedItem.url}>
              <div
                className={`Sidebar__menuItem__content__nested ${
                  nestedIdx === activeNestedIndex ? 'active' : ''
                }`}
                onClick={() => handleNestedItemClick(nestedIdx)}
              >
                <div className='Sidebar__menuItem__content__wrapper'>
                  <img className='Sidebar__menuItem__content--icon' src={nestedItem.icon} alt="" />
                </div>
                <span>{nestedItem.label}</span>
              </div>
            </Link>
          ))}
        </ul>
      )}
    </li>
  );
};

export default SideBarMenuItem;


