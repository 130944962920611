import { Box, Typography } from "@mui/material"
import "./StylesToggleSquare.scss"

const ToggleSquare = ({
  leftLabel = "YES",
  rightLabel = "NO",
  onClick,
  active,
  noSpacing,
  sx,
}) => {
  return (
    <Box
      className="toggler"
      style={{ marginRight: noSpacing ? "0px" : "" }}
      sx={sx}
    >
      <Typography
        onClick={() => onClick(leftLabel)}
        className={active===leftLabel ? `toggler_text active` : `toggler_text`}
        variant="h6"
      >
        {leftLabel}
      </Typography>
      <Typography
        onClick={() => onClick(rightLabel)}
        className={active===rightLabel ? `toggler_text active` : `toggler_text`}
        variant="h6"
      >
        {rightLabel}
      </Typography>
    </Box>
  )
}

export default ToggleSquare
