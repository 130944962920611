import { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Box, TableRow } from "@mui/material";

import { LIMIT } from "../../constants";
import { convertDate } from "../../utils/convertDate";
import { getTransactions } from "../../api/transactions";
import { getEvents } from "../../api/events";
import useDebounce from "../../hooks/useDebounce";
import Button from "../../components/common/button/Button";
import Page from "../../components/common/page/Page";
import Table from "../../components/table/Table";
import ellipsisIcon from "../../assets/icons/ellipsis.png";
import ActionList from "../../components/actionsList/ActionList";
import TransactionInformation from "../../components/transactionInformation/TransactionInformation";
import ProcessRefundModal from "../../components/modals/processRefundModal/ProcessRefundModal";
import CompeteOrderModal from "../../components/modals/completeOrderModal/CompeteOrderModal";
import ReopenOrderModal from "../../components/modals/reopenOrderModal/ReopenOrderModal";
import viewOrderIcon from "../../assets/icons/viewOrderIcon.png";
import validatePendingIcon from "../../assets/icons/validatePendingIcon.png";
import transactionIcon from "../../assets/icons/transactionIcon.png";
import deliveryIcon from "../../assets/icons/deliveryIcon.png";
import bankTransferIcon from "../../assets/icons/bankTransferIcon.png";
import checkCircleIcon from "../../assets/icons/checkCircleIcon.svg";
import errorCircleIcon from "../../assets/icons/errorCircleIcon.svg";
import pendingCircleIcon from "../../assets/icons/iconPending.svg";
import TableFilter from "../../components/tableFilter/TableFIlter";
import CustomTabelCell from "../../components/common/CustomTebleCell/CustomTableCell";
import { getTransactionById } from "../../utils/getTransactionById";
import moment from "moment";
import ValidatePendingModal from "../../components/modals/validatePendingModal/ValidatePendingModal";

// const transformTransactionData = (transaction) => {
//   if (transaction) {
//     return transaction.map((item) => ({
//       orderId: item?.id,
//       status: item?.status,
//       userEmail: item?.user?.email,
//       type: item?.orderItem[0]?.type === 'STREAM_PASS' ? 'Stream Pass' : 'Spray Money',
//       itemsInOrder: item?.orderItem?.length,
//       orderItem: item?.orderItem
//     }));
//   }

//   return [];
// }

const filterTransactionStatus = [
  { label: "All - Payment status", value: "placeholder" },
  { label: "Success", value: "SUCCESS" },
  { label: "Failed", value: "FAILED" },
  { label: "Canceled", value: "CANCELED" },
  { label: "Pending", value: "PENDING" },
];

const filterOrderType = [
  { label: "Purchase Type", value: "placeholder" },
  { label: "Spray Money", value: "SPRAY_MONEY" },
  { label: "Stream Pass", value: "STREAM_PASS" },
];

const tableHeads = [
  { label: "OrderID" },
  { label: "User Email" },
  { label: "Created At" },
  { label: "Purchased at" },
  { label: "Type" },
  { label: "Items" },
  { label: "Status" },
  { label: ellipsisIcon, isIcon: true },
];

const Transactions = () => {
  const navigator = useNavigate();
  const [filters, setFilters] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });
  const [searchEvent, setSearchEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [selectTransaction, setSelectTransaction] = useState({});
  const [isShowTransactionInfo, setIsShowTransactionInfo] = useState(false);
  const [isShowProcessRefundModal, setIsShowProcessRefundModal] =
    useState(false);
  const [isShowCompleteOrderModal, setIsShowCompleteOrderModal] =
    useState(false);
  const [isShowReopenOrderModal, setIsShowReopenOrderModal] = useState(false);
  const debouncedSearchEvent = useDebounce(searchEvent, 300);
  const [isShowValidatePendingModal, setIsShowValidatePendingModal] =
    useState(false);

  const debouncedFilters = useDebounce(filters, 3000000);

  const { data: eventData, refetch: eventRefetch } = useQuery({
    retry: 0,
    queryKey: ["events"],
    queryFn: () =>
      getEvents({
        page: pagination?.pageIndex,
        limit: LIMIT,
        search: searchEvent,
      }),
  });
  const { data, refetch, error } = useQuery({
    retry: 0,
    queryKey: ["transactions"],
    queryFn: () =>
      getTransactions({
        page: pagination.pageIndex,
        limit: LIMIT,
        status: filters?.status,
        eventId: filters?.eventId,
        search: filters?.search,
      }),
  });


  useEffect(() => {
    eventRefetch();
  }, [debouncedSearchEvent]);

  useEffect(() => {
    refetch();
  }, [pagination.pageIndex, debouncedFilters]);

  useEffect(() => {
    if (eventData) {
      setEvents(() => {
        return eventData?.data.map((event) => {
          return {
            label: event?.title,
            id: event.id,
          };
        });
      });
    }
  }, [eventData]);

  const handleCloseTransactionInfo = () =>
    setIsShowTransactionInfo(() => false);
  const handleCloseProcessRefundModal = () =>
    setIsShowProcessRefundModal(() => false);
  const handleCloseCompleteOrderModal = () =>
    setIsShowCompleteOrderModal(() => false);
  const handleCloseReopenOrderModal = () =>
    setIsShowReopenOrderModal(() => false);

  const handleCreateNewTransaction = () => navigator("/createTransaction");

  const handleOpenReopenOrderModal = (transactionId) => {
    if (data) {
      const { data: transactionData } = data;
      const transaction = getTransactionById(transactionData, transactionId);
      setSelectTransaction(transaction);
      setIsShowReopenOrderModal(() => true);
    }
  };

  const handleOpenCompleteOrderModal = (transactionId) => {
    if (data) {
      const { data: transactionData } = data;

      const transaction = getTransactionById(transactionData, transactionId);

      setSelectTransaction(transaction);
      setIsShowCompleteOrderModal(() => true);
    }
  };

  const handleShowTransactionInfo = (transactionId) => {
    if (data) {
      const { data: transactionData } = data;
      const transaction = getTransactionById(transactionData, transactionId);
      setSelectTransaction(transaction);
      setIsShowTransactionInfo(() => true);
    }
  };

  const handleOpenProcessRefundModal = (transactionId) => {
    if (data) {
      const { data: transactionData } = data;

      const transaction = getTransactionById(transactionData, transactionId);

      setSelectTransaction(transaction);
      setIsShowProcessRefundModal(() => true);
    }
  };

  const handleChangeFilter = (field, event) => {
    const value = event.target.value;
    if (field === "event") {
      setSearchEvent(value);
      const eventId = events?.find(({ label }) => label === value);

      return setFilters((prevState) => {
        return {
          ...prevState,
          ["eventId"]: eventId?.id,
        };
      });
    }

    setFilters((prevState) => {
      return {
        ...prevState,
        [field]: value === "placeholder" ? "" : value,
      };
    });
  };

  const handleValidatePending = (transaction) => {
    setIsShowValidatePendingModal(() => true);

    setSelectTransaction(transaction);
  };

  const handleCloseValidatePendingModal = () =>
    setIsShowValidatePendingModal(() => false);

  const actionsPageButton = useMemo(
    () => (
      <Button onClick={handleCreateNewTransaction}>Create Transaction</Button>
    ),
    []
  );
  return (
    <Page title="Transactions" action={actionsPageButton}>
      {isShowReopenOrderModal && (
        <ReopenOrderModal
          selectTransactionId={selectTransaction?.id}
          handleClose={handleCloseReopenOrderModal}
          refetch={refetch}
        />
      )}
      {isShowCompleteOrderModal && (
        <CompeteOrderModal
          handleClose={handleCloseCompleteOrderModal}
          selectTransactionId={selectTransaction?.id}
          refetch={refetch}
        />
      )}
      {isShowProcessRefundModal && (
        <ProcessRefundModal
          handleCloseProcessRefundModal={handleCloseProcessRefundModal}
          selectTransactionId={selectTransaction?.id}
          refetch={refetch}
        />
      )}
      {isShowTransactionInfo && (
        <TransactionInformation
          onClose={handleCloseTransactionInfo}
          selectTransaction={selectTransaction}
        />
      )}
      {isShowValidatePendingModal && (
        <ValidatePendingModal
          selectTransactionId={selectTransaction?.id}
          handleClose={handleCloseValidatePendingModal}
          refetch={refetch}
        />
      )}
      <Box>
        <TableFilter
          autocompliteData={events}
          autocompliteTextName="event"
          autocomplitePlaceholder="Livestream Title"
          handleFilters={handleChangeFilter}
          textFieldPlaceholder="Search for Customer"
          selectOptions={filterTransactionStatus}
          purchaseTypeOptions={filterOrderType}
          textFieldName="search"
          selectFieldName="status"
        />
        <Table
          pagination={pagination}
          setPagination={setPagination}
          page={pagination?.pageIndex}
          meta={data?.meta}
          error={error}
          headers={tableHeads}
        >
          {data?.data.map((transaction) => {
            return (
              <TableRow
                key={transaction?.id}
                style={{ fontFamily: "Baguede" }}
                sx={{
                  background: "#FAFAFA 0% 0% no-repeat padding-box",
                  borderBottom: "7px solid white",
                }}
              >
                <CustomTabelCell>{transaction?.id}</CustomTabelCell>
                <CustomTabelCell>{transaction?.user?.email}</CustomTabelCell>
                 <CustomTabelCell>
                  {transaction?.createdAt
                    ? moment(transaction?.createdAt).format(
                        "DD:MM:YYYY"
                      )
                    : "-"}
                </CustomTabelCell>
                <CustomTabelCell>
                  {transaction?.transaction?.date
                    ? moment(transaction?.transaction?.date).format(
                        "DD:MM:YYYY"
                      )
                    : "-"}
                </CustomTabelCell>
                <CustomTabelCell>
                  {transaction?.orderItem[0]?.type === "STREAM_PASS"
                    ? "Stream Pass"
                    : "Spray Money"}
                </CustomTabelCell>
                <CustomTabelCell>
                  {transaction?.orderItem?.length}
                </CustomTabelCell>
                <CustomTabelCell>
                  {transaction?.status === "SUCCESS" ? (
                    <Box>
                      <img src={checkCircleIcon} alt="circle" />
                    </Box>
                  ) : transaction?.status === "PENDING" ? (
                    <Box>
                      <img src={pendingCircleIcon} alt="circle" />
                    </Box>
                  ) : transaction?.status === "FAILED" ? (
                    <Box>
                      <img src={errorCircleIcon} alt="circle" />
                    </Box>
                  ) : (
                    <Box>
                      <img src={errorCircleIcon} alt="circle" />
                    </Box>
                  )}
                </CustomTabelCell>
                <CustomTabelCell>
                  <ActionList
                    actions={[
                      {
                        label: "View Order",
                        icon: viewOrderIcon,
                        action: () =>
                          handleShowTransactionInfo(transaction?.id),
                        status: transaction.status,
                      },
                      {
                        label: "Refund Order",
                        icon: validatePendingIcon,
                        action: () =>
                          handleOpenProcessRefundModal(transaction?.id),
                        status: transaction.status,
                      },
                      {
                        label: "Mark as Complete",
                        icon: transactionIcon,
                        action: () =>
                          handleOpenCompleteOrderModal(transaction?.id),
                        status: transaction.status,
                      },
                      {
                        label: "Reopen Order",
                        icon: deliveryIcon,
                        action: () =>
                          handleOpenReopenOrderModal(transaction?.id),
                        status: transaction.status,
                      },
                      {
                        label: "Validate Pending",
                        icon: bankTransferIcon,
                        action: () =>
                          handleValidatePending(transaction?.transaction),
                        status: transaction.status,
                      },
                    ]}
                  />
                </CustomTabelCell>
              </TableRow>
            );
          })}
        </Table>
      </Box>
    </Page>
  );
};

export default Transactions;
