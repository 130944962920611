export const MergeTagsMockDB = [
  {
    key: "First Name",
    value: "{{First Name}}",
    mergetag: "first_name",
    sample: "First Name",
  },
  {
    key: "Last Name",
    value: "{{Last Name}}",
    mergetag: "last_name",
    sample: "Last Name",
  },
  {
    key: "Username",
    value: "{{Username}}",
    mergetag: "username",
    sample: "Username",
  },
  {
    key: "Email",
    value: "{{Email}}",
    mergetag: "email",
    sample: "Email",
  },
  {
    key: "Phone Number",
    value: "{{Phone Number}}",
    mergetag: "phone_number",
    sample: "Phone Number",
  },
  {
    key: "Event Title",
    value: "{{Event Title}}",
    mergetag: "event_title",
    sample: "Event Title",
  },
  {
    key: "Stream Pass Title",
    value: "{{Stream Pass Title}}",
    mergetag: "stream_pass_title",
    sample: "Stream Pass Title",
  },
  {
    key: "Stream Pass Price",
    value: "{{Stream Pass Price}}",
    mergetag: "stream_pass_price",
    sample: "Stream Pass Price",
  },
  {
    key: "Artist Name",
    value: "{{Artist Name}}",
    mergetag: "artist_name",
    sample: "Artist Name",
  },
  {
    key: "WalletID",
    value: "{{WalletID}}",
    mergetag: "wallet_id",
    sample: "WalletID",
  },
  {
    key: "OrderID",
    value: "{{OrderID}}",
    mergetag: "order_id",
    sample: "OrderID",
  },
  {
    key: "Registration Date",
    value: "{{Registration Date}}",
    mergetag: "registration_date",
    sample: "Registration Date",
  },
  {
    key: "Address",
    value: "{{Address}}",
    mergetag: "address",
    sample: "Address",
  },
  {
    key: "Address 2",
    value: "{{Address 2}}",
    mergetag: "address_2",
    sample: "Address 2",
  },
  {
    key: "ServiceFee",
    value: "{{ServiceFee}}",
    mergetag: "service_fee",
    sample: "ServiceFee",
  },
];
