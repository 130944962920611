import { Grid, CssBaseline } from '@mui/material';

const AuthorizationLayout = ({ img, children }) => (
  <Grid
      item
    container
    component='main'
    sx={{ height: '100vh', }}
    xs={16}
  >
    <CssBaseline />
    <Grid
      item
      sm={4}
      md={4}
      xs={8}
      sx={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'left',
      }}
    />
    <Grid item 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      xs={8}
     >

      {children}
    </Grid>
  </Grid>
)

export default AuthorizationLayout;