import { useState } from "react";
import { useFormikContext, Field, Form } from "formik";
import { Grid, Box } from "@mui/material";

import { getYearsList } from "../../../utils/getYearsList";
import { MONTH, GENDER_OPTION } from "../../../constants";
import { getDaysForMonth } from "../../../utils/getDaysForMonth";
import Input from "../../../components/common/input/Input";
import FormWrapper from "../../../components/common/formWrapper/FormWrapper";
import Button from "../../../components/common/button/Button";
import Select from "../../../components/common/select/Select";
import CountryCodeInput from "../../../components/countryCodeInput/CountryCodeInput";

import "./StylesContanctInfoForm.scss";

const ContactInfoForm = ({
  isNewUser,
  isLoading,
  userData,
  updateUserLoading,
}) => {
  const years = getYearsList();
  const [selectMonth, setSelectMonth] = useState(MONTH[0]?.value);
  const [selectYer, setSelectYear] = useState(null);
  const days = getDaysForMonth(selectYer, selectMonth);

  const genderLabel = GENDER_OPTION.find(
    ({ label, value }) => value === userData?.data?.gender
  )?.label;
  const {
    values: { birthday, countryCode, gender, year, month },
    errors,
    touched,
  } = useFormikContext();

  return (
    <FormWrapper>
      <Form>
        <Grid container columns={{ xs: 1, sm: 1, md: 1, xl: 12 }}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Field
                error={errors?.userName && touched?.userName}
                helperText={errors?.userName}
                component={Input}
                label="Username"
                name="userName"
                id="userName"
              />
              <Field
                component={Input}
                label="Email Address"
                name="emailAddress"
                error={errors?.emailAddress && touched?.emailAddress}
                helperText={errors?.emailAddress}
              />
              <Box
                sx={{
                  display: "flex",
                  gap: "2rem",
                }}
              >
                <Box
                  sx={{
                    width: "30%",
                  }}
                >
                  <Field
                    component={CountryCodeInput}
                    label="Country Code"
                    name="countryCode"
                    defaultValue={countryCode}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Field
                    component={Input}
                    label="Phone Number"
                    name="phoneNumber"
                    type="number"
                  />
                </Box>
              </Box>
              <Field
                component={Select}
                defaultValue={{
                  label: genderLabel,
                  value: gender,
                }}
                label="Gender"
                name="gender"
                options={GENDER_OPTION}
                error={errors?.gender && touched?.gender}
                helperText={errors?.gender}
              />
              <Box
                sx={{
                  display: "flex",
                  gap: "2rem",
                }}
              >
                <Field
                  component={Select}
                  selectData={setSelectYear}
                  options={years}
                  name="year"
                  defaultValue={{ label: year, value: year }}
                  label="Year"
                />
                <Field
                  component={Select}
                  selectData={setSelectMonth}
                  options={MONTH}
                  defaultValue={{ label: month, value: month }}
                  name="month"
                  label="Month"
                />
                <Field
                  component={Select}
                  name="birthday"
                  defaultValue={{ label: birthday, value: birthday }}
                  options={days}
                  label="Birthday"
                />
              </Box>
              <Field
                error={errors?.password && touched?.password}
                helperText={errors?.password}
                type="password"
                component={Input}
                label="Password"
                name="password"
              />
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box
              className="test"
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                isModal
                isLoading={isLoading || updateUserLoading}
              >
                {isNewUser ? "Invite to Platform" : "Save"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormWrapper>
  );
};

export default ContactInfoForm;
