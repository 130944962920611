import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./StylesFormWrapper.scss";

const FormWrapper = ({ children, editorForm }) => {
  const location = useLocation();
  const [lock, setLocation] = useState("");

  useEffect(() => {
    setLocation(location.search);
  }, [location.search]);

  return (
    <div
      className="FormWrapper"
      style={{
        padding:
          lock.includes("tab=streamPasses") || lock.includes("tab=sprayMoney")
            ? "0"
            : "2rem",
      }}
    >
      {children}
    </div>
  );
};

export default FormWrapper;
