import { TextField, Box, Typography } from "@mui/material"

import "./StylesInput.scss"
const styles = {
  placeholder: {
    fontFamily: "Baguede",
    letterSpacing: "-0.36px",
    color: "#000000",
  },
  "::placeholder": {
    fontWeight: 300,
    fontFamily: "Baguede",
  },
}
const Input = ({
  field,
  props,
  label,
  placeholder,
  subText,
  type,
  multiline,
  rows,
  error,
  helperText,
  defaultValue,
  disabled,
  form,
  style,
}) => {
  let string = field?.name

  let parts = string.split(/\.|\[|\]/).filter(part => part.length > 0)

  let passErrors = form.errors

  for (let part of parts) {
    if (passErrors && passErrors.hasOwnProperty(part)) {
      passErrors = passErrors[part]
    } else {
      passErrors = undefined
      break
    }
  }

  if (form?.errors[field?.name]) {
    helperText = form?.errors[field?.name]
  }

  if (typeof passErrors === "string") {
    helperText = passErrors
  }

  return (
    <Box style={{ marginBottom: "10px" }} className="wrapperInputBlock">
      <label className="wrapperInputBlock__label">{label}</label>
      <TextField
        helperText={
          error || form?.errors[field?.name] || typeof passErrors === "string"
            ? helperText
            : ""
        }
        className="customInput"
        error={
          error || form?.errors[field?.name] || typeof passErrors === "string"
        }
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        type={type}
        variant="outlined"
        InputProps={{
          style: styles.placeholder,
        }}
        defaultValue={defaultValue}
        sx={{
          width: "100%",
          "& .MuiInputBase-input": {
            fontSize: "1.8rem",
            fontFamily: "Baguede",
          },
          "& .MuiFormHelperText-root": {
            fontSize: "1.1rem",
            marginLeft: 0,
            fontFamily: "Baguede",
          },
        }}
        placeholder={placeholder}
        {...field}
        {...props}
      />
      {subText && (
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            fontSize: "1rem",
            whiteSpace: "nowrap"
          }}
          style={{ fontFamily: "Baguede" }}
        >
          {subText}
        </Typography>
      )}
    </Box>
  )
}

export default Input
