import AuthorizationLayout from '../../layouts/AuthorizationLayout';
import logo from '../../assets/images/pexels-breston-3.png';
import ResetPasswordForm from '../../forms/resetPasswordForm/ResetPasswordForm'

const ResetPasswordPage = () => (
  <AuthorizationLayout
    img={logo}
  >
    <ResetPasswordForm />
  </AuthorizationLayout>
);

export default ResetPasswordPage;