import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Box } from "@mui/material";
import { useFormikContext } from "formik";
import './StylesChooseInput.scss';
import { makeStyles } from '@mui/styles'; 
const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  option: {
    fontFamily: 'Baguede', // Example: apply custom font family to the dropdown options
  },
  listbox: {
    fontFamily: 'Baguede', // Example: apply custom font family to the dropdown options
  },
}));
export default function ChooseArtistInput({
  options,
  isLoading,
  setArtistSearchTerm,
  handleCreateArtist,
  handleSelectArtist,
  createArtistError,
}) {
  const { setFieldValue, values, errors } = useFormikContext();

  const [value, setValue] = React.useState(
    values?.artistName || values?.artist?.name || ""
  );

    const classes = useStyles();

  const error = () => {
    if (createArtistError === "is not allowed to be empty") {
      return "Image is not allowed to be empty";
    }

    if (errors?.artistId) {
      return errors?.artistId;
    }

    return "";
  };

  return (
    <Autocomplete
          disablePortal
          style={{fontFamily: "Baguede"}}
      value={value}
      loading={isLoading}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            name: newValue.inputValue,
          });
          handleCreateArtist({ name: newValue.inputValue });
        } else {
          setValue(newValue);
          handleSelectArtist({ artist: newValue });
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Create "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.name;
        }
        // Regular option
        return option.name;
      }}
      freeSolo
      fullWidth
      renderInput={(params) => (
        <Box className="Autocomplete__renderInput">
          <label style={{ fontFamily: "Baguede" }}>{"Artist"}</label>
          <TextField
            error={error()}
            helperText={error()}
            style={{ fontFamily: "Baguede" }}
            sx={{
              width: "100%",
              fontSize: "1.8rem",
              "& .MuiInputBase-input": {
                fontSize: "1.8rem",
                fontFamily: "Baguede",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "1.1rem",
                marginLeft: 0,
                fontFamily: "Baguede",
              },
              "& .MuiAutocomplete-popupIndicator": {
                fontFamily: "Baguede",
              },
              "& .MuiAutocomplete-inputRoot": {
                fontFamily: "Baguede",
                },
                "MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input": {
                  fontFamily: "Baguede"
              }
            }}
            onChange={(e) => {
              setArtistSearchTerm(() => e.target.value);
            }}
                  {...params}
            classes={{
            option: classes.option,
            listbox: classes.listbox,
      }}
          />
        </Box>
      )}
    />
  );
}
