import React from "react";
import { Button, styled } from "@mui/material";
import variables from "../../../assets/styles/variables.scss";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "var($color-white)",
  backgroundColor: variables["color-orange"],
  "&:hover": {
    backgroundColor: variables["color-deep-orange"],
  },
  fontSize: "2rem",
  textTransform: "none",
  borderRadius: 0,
  "&.Mui-disabled": {},
}));

const ContinueButton = ({ handleClick, isDisabled }) => {
  return (
    <div>
      <ColorButton
        variant="contained"
        onClick={handleClick}
        disabled={isDisabled}
      >
        Continue
      </ColorButton>
    </div>
  );
};

export default ContinueButton;
