import { Grid, Box, Typography } from "@mui/material"
import { Field, useFormikContext, FieldArray } from "formik"
import { useNavigate } from "react-router-dom"
import { useState } from "react"

import UploadEventPrint from "../../components/common/uploadEventPrint/UploadEventPrint"
import AddFieldButton from "../../components/common/addFieldButton/AddFieldButton"
import Input from "../../components/common/input/Input"
import heroSection from "../../assets/images/heroSection.png"
import artistGallery from "../../assets/images/artistGallery.png"
import videoCarousel from "../../assets/images/videoCarousel.png"
import EventVision from "../../assets/images/eventVision.png"
import "./StylesEventContentForm.scss"
import ContinueButton from "../../components/common/ContinueButton/ContinueButton"

const EventContentForm = ({
  setTmpPreview,
  tmpPreview,
  handleChangeActiveHeadMenu,
  touched,
  errors,
}) => {
  const navigator = useNavigate()
  const {
    values: {
      eventVisionSponsors,
      eventVisionHeaderUrls,
      artistGalleryUrls,
      heroSectionUrls,
      videoCarouselUrls,
    },
  } = useFormikContext()

  const [initEventVisionHeaderUrls] = useState(() => {
    if (eventVisionHeaderUrls.length) {
      if (eventVisionHeaderUrls.length === 1) {
        return [...eventVisionHeaderUrls, ""]
      }
      return [...eventVisionHeaderUrls]
    }
    return [{}, {}]
  })

  const [initEventVisionSponsors, setInitEventVisionSponsors] = useState(() => {
    if (eventVisionSponsors.length) {
      if (eventVisionSponsors.length === 1) {
        return [...eventVisionSponsors, ""]
      }
      return [...eventVisionSponsors]
    }
    return [{}, {}]
  })
  const [initHeroSectionUrls] = useState(() => {
    if (heroSectionUrls.length) {
      if (heroSectionUrls.length < 2) {
        return [...heroSectionUrls, ""]
      }

      return heroSectionUrls
    }

    return [{}, {}]
  })
  const [initVideoCarouselUrls, setVideoCarouselUrls] = useState(() => {
    if (videoCarouselUrls.length) {
      if (videoCarouselUrls.length === 1) {
        return [...videoCarouselUrls, ""]
      }
      return [...videoCarouselUrls]
    }
    return [{}, {}]
  })
  const [initArtistGalleryUrls] = useState(() => {
    if (artistGalleryUrls) {
      return [...artistGalleryUrls, "", "", ""].slice(0, 4)
    }
    return [{}, {}, {}, {}]
  })
  const changeTab = tab => {
    const STREAM_PASSES_TAB_INDEX = 2
    handleChangeActiveHeadMenu(STREAM_PASSES_TAB_INDEX)

    navigator(window.location.search.replace(/(tab)=[^&]*/g, `${tab}`))
  }

  const handleContinue = () => changeTab("tab=streamPasses")

  return (
    <div className="EventContentForm">
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ paddingTop: "2rem" }}>
            <Field
              component={Input}
              label="Upload Music File/Link"
              name="musicUrl"
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          paddingTop: "2rem",
          marginTop: "5rem",
          borderBottom: "1px dashed #E7E6EB",
          paddingBottom: "2rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: "Baguede",
                letterSpacing: "-0.32px",
                color: "#000000",
                opacity: 1,
              }}
              variant="h4"
              gutterBottom
            >
              Hero Section
            </Typography>
            <Typography
              style={{
                fontFamily: "Baguede",
                letterSpacing: "-0.32px",
                color: "#000000",
                opacity: 0.9,
              }}
              variant="h5"
              gutterBottom
            >
              Please fill out the information required based on the event.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <img
              style={{ maxWidth: "100%", width: "100%" }}
              src={heroSection}
              alt="eventImage"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={Input}
              label="Event Title"
              name="heroSectionTitle"
            />
            <Field
              component={Input}
              label="Event Description*"
              name="heroSectionDescription"
              multiline
              rows={4}
              error={
                errors?.heroSectionDescription &&
                touched?.heroSectionDescription
              }
              helperText={errors?.heroSectionDescription}
            />
            <FieldArray
              name="heroSectionUrls"
              render={arrayHelpers => (
                <Box>
                  {initHeroSectionUrls.map((item, index) => (
                    <UploadEventPrint
                      key={index}
                      type={index === 0 ? "vertical" : "horizontal"}
                      label={
                        index === 1 ? "Horizontal Image" : "Vertical Image"
                      }
                      subText="Image will be resized to width: 499px, height: 722px"
                      blockName="heroSection"
                      fieldName={`heroSectionUrls_${index}`}
                      index={index}
                      tmpPreview={tmpPreview}
                      setTmpPreview={setTmpPreview}
                      arrayHelpers={arrayHelpers}
                      item={item}
                    />
                  ))}
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          paddingTop: "2rem",
          marginTop: "5rem",
          borderBottom: "1px dashed #E7E6EB",
          paddingBottom: "2rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: "Baguede",
                letterSpacing: "-0.32px",
                color: "#000000",
                opacity: 1,
              }}
              variant="h4"
              gutterBottom
            >
              Video Carousel
            </Typography>
            <Typography
              style={{
                fontFamily: "Baguede",
                letterSpacing: "-0.32px",
                color: "#000000",
                opacity: 0.93,
              }}
              variant="h5"
              gutterBottom
            >
              Please fill out the information required based on the event.
            </Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <img
              style={{ maxWidth: "100%", width: "100%" }}
              src={videoCarousel}
              alt="eventImage"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={Input}
              label="Section Title"
              name="videoCarouselTitle"
              style={{ margin: "10px 0", border: "2px solid red" }}
            />
            <Field
              component={Input}
              label="Section Body*"
              name="videoCarouselDescription"
              multiline
              rows={4}
              error={
                errors?.videoCarouselDescription &&
                touched?.videoCarouselDescription
              }
              helperText={errors?.videoCarouselDescription}
            />
            <Typography
              style={{
                fontFamily: "Baguede",
                letterSpacing: "-0.32px",
                color: "#000000",
                opacity: 1,
                margin: "40px 0 20px 0",
              }}
              variant="h5"
            >
              Add video to Carousel "(Minimum 2)"
            </Typography>
            <FieldArray
              name="videoCarouselUrls"
              render={arrayHelpers => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {initVideoCarouselUrls.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                      style={{
                        marginBottom: "30px",
                      }}
                    >
                      <Field
                        component={Input}
                        label={`Video Title ${index + 1}`}
                        name={`videoCarouselUrls[${index}].title`}
                      />
                      <Field
                        component={Input}
                        label="Upload Video File/Link*"
                        name={`videoCarouselUrls[${index}].url`}
                      />
                      <UploadEventPrint
                        key={index}
                        type="horizontal"
                        label={`Cover Image Video ${index + 1}`}
                        subText="Image will be resized to width: 499px, height: 722px"
                        blockName="videoCarouselUrls"
                        fieldName={`videoCarouselUrls[${index}].cover`}
                        index={index}
                        tmpPreview={tmpPreview}
                        setTmpPreview={setTmpPreview}
                        arrayHelpers={arrayHelpers}
                        item={item?.cover}
                      />
                    </Box>
                  ))}
                  <AddFieldButton
                    text="Add Video Field"
                    type="button"
                    onClick={() => {
                      arrayHelpers.push({ title: "", url: "", cover: "" })
                      setVideoCarouselUrls(prevState => {
                        return [...prevState, ""]
                      })
                    }}
                  />
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          paddingTop: "2rem",
          marginTop: "5rem",
          borderBottom: "1px dashed #E7E6EB",
          paddingBottom: "2rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: "Baguede",
                letterSpacing: "-0.32px",
                color: "#000000",
                opacity: 1,
              }}
              variant="h4"
              gutterBottom
            >
              Artist Gallery
            </Typography>
            <Typography
              style={{
                fontFamily: "Baguede",
                letterSpacing: "-0.32px",
                color: "#000000",
                opacity: 0.93,
              }}
              variant="h5"
              gutterBottom
            >
              Please fill out the information required based on the event.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <img
              style={{ width: "100%", maxWidth: "100%" }}
              src={artistGallery}
              alt="eventImage"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={Input}
              label="Event Description"
              name="artistGalleryDescription"
              error={
                errors?.artistGalleryDescription &&
                touched?.artistGalleryDescription
              }
              helperText={errors?.artistGalleryDescription}
              multiline
              rows={4}
            />
            <FieldArray
              name="artistGalleryUrls"
              render={arrayHelpers => (
                <div>
                  {initArtistGalleryUrls.map((item, index) => (
                    <UploadEventPrint
                      key={index}
                      type="vertical"
                      label={`Vertical Image ${index + 1}`}
                      subText="Image will be resized to width: 499px, height: 722px"
                      blockName="artistGallery"
                      fieldName={`rtistGallery_${index}`}
                      index={index}
                      tmpPreview={tmpPreview}
                      setTmpPreview={setTmpPreview}
                      arrayHelpers={arrayHelpers}
                      item={item}
                    />
                  ))}
                </div>
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          paddingTop: "2rem",
          marginTop: "5rem",
          borderBottom: "1px dashed #E7E6EB",
          paddingBottom: "2rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: "Baguede",
                letterSpacing: "-0.32px",
                color: "#000000",
                opacity: 1,
              }}
              variant="h4"
              gutterBottom
            >
              Event Vision
            </Typography>
            <Typography
              style={{
                fontFamily: "Baguede",
                letterSpacing: "-0.32px",
                color: "#000000",
                opacity: 0.93,
              }}
              variant="h5"
              gutterBottom
            >
              Please fill out the information required based on the event.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <img
              style={{ width: "100%", maxWidth: "100%" }}
              src={EventVision}
              alt="eventImage"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={Input}
              label="First Line Header"
              name="eventVisionHeaderFirst"
            />
            <Field
              component={Input}
              label="Second Line Header"
              name="eventVisionHeaderSecond"
            />
            <Field
              component={Input}
              label="Event Description*"
              name="eventVisionHeaderDescription"
              error={
                errors?.eventVisionHeaderDescription &&
                touched?.eventVisionHeaderDescription
              }
              helperText={errors?.eventVisionHeaderDescription}
              multiline
              rows={4}
            />
            <Box
              sx={{
                marginTop: "4rem",
              }}
            >
              <Typography style={{ margin: "8px 0" }} variant="h5">
                Event Vision Image Urls
              </Typography>
              <FieldArray
                name="eventVisionHeaderUrls"
                render={arrayHelpers => (
                  <Box>
                    {initEventVisionHeaderUrls.map((item, index) => (
                      <UploadEventPrint
                        key={index}
                        type={index === 0 ? "horizontal" : "vertical"}
                        label={
                          index === 0 ? "Horizontal Image" : "Vertical Image"
                        }
                        subText="Image will be resized to width: 499px, height: 722px"
                        blockName="eventVisionHeaderUrls"
                        fieldName={`event_vision_header_url_${index}`}
                        index={index}
                        tmpPreview={tmpPreview}
                        setTmpPreview={setTmpPreview}
                        arrayHelpers={arrayHelpers}
                        item={item}
                      />
                    ))}
                  </Box>
                )}
              />
            </Box>
            <Box
              sx={{
                marginTop: "5rem",
              }}
            >
              <Typography style={{ margin: "8px 0" }} variant="h5">
                Event Sponsors
              </Typography>
              <FieldArray
                name="eventVisionSponsors"
                render={arrayHelpers => (
                  <Box>
                    {initEventVisionSponsors.map((item, index) => (
                      <UploadEventPrint
                        key={index}
                        type="vertical"
                        label="Vertical Image"
                        subText="Image will be resized to width: 499px, height: 722px"
                        blockName="sponsor"
                        fieldName={`sponsor_${index}`}
                        index={index}
                        tmpPreview={tmpPreview}
                        setTmpPreview={setTmpPreview}
                        arrayHelpers={arrayHelpers}
                        item={item}
                      />
                    ))}
                    <AddFieldButton
                      type="button"
                      text="Add Sponsor"
                      onClick={() => {
                        arrayHelpers.push({ imgContent: "", imgType: "" })
                        setInitEventVisionSponsors(prevState => {
                          return [...prevState, ""]
                        })
                      }}
                    ></AddFieldButton>
                  </Box>
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          textAlign: "end",
          padding: "3rem 2rem",
        }}
      >
        <ContinueButton handleClick={handleContinue} />
      </Box>
    </div>
  )
}

export default EventContentForm
