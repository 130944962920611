import { Field, Form, useFormikContext } from "formik";
import { Grid, Box, Typography } from "@mui/material";
import { Accordion } from "../../components/accordion/accordion";
import Button from "../../components/common/button/Button";
import BasicTimePicker from "../../components/common/dateTimePicker/TimePicker";
import BasicDatePicker from "../../components/common/dateTimePicker/DatePicker";
import Input from "../../components/common/input/Input";
import Select from "../../components/common/select/Select";
import Autocomplete from "../../components/common/autocomplete/Autocomplite";
import { useQuery } from "react-query";
import { getUsers } from "../../api/user";
import { LIMIT } from "../../constants";
import ToggleSquare from "../../components/common/toggleSwitch/ToggleSquare";
import { MergeTagsMockDB } from "./EditData";
import "./StylesEditEmailForm.scss";
import React, {
  useRef,
  useEffect,
  forwardRef,
  useState,
  useCallback,
} from "react";
import EmailEditor from "react-email-editor";

const options = [
  { label: "Event Emails", value: "EVENT" },
  { label: "Marketing Emails", value: "MARKETING" },
  { label: "User Emails", value: "USER" },
];

const ForwardedEmailEditor = forwardRef((props, ref) => (
  <EmailEditor {...props} ref={ref} />
));

const EditEmailForm = ({
  events,
  handleChangeSearch,
  isLoading,
  errors,
  touched,
}) => {
  const {
    values: { type, content },
    setFieldValue,
    submitForm,
    validateForm,
  } = useFormikContext();

  const emailEditorRef = useRef(null);
  const [htmlLoading, setHtmlLoading] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [design, setDesign] = useState(null);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [fieldWithError, setFieldWithError] = useState(""); // State to store field with error
  const [active, setActive] = useState("NO");
  const [users, setUsers] = useState([]);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [MergeTag, setMergeTag] = useState("");
  const [Done, setDone] = useState(null);
  const [PidState, setPidState] = useState(null);

  const {
    isLoading: isLoadingUser,
    data: userData,
    refetch: userRefetch,
  } = useQuery({
    queryKey: ["users"],
    queryFn: () => getUsers({ limit: LIMIT, search: userSearchTerm }),
    retry: 0,
  });

  const handleSendImmediately = (value) => {
    if (value === "YES") {
      setActive("YES")
      setFieldValue("sendImmediate", true);
    }
    if (value === "NO") {
      setActive("NO")
      setFieldValue("sendImmediate", false);
    }
  };
  const handleChengeUserAutocomplite = ({ label }) => {
    setUserSearchTerm(label);
    setFieldValue("userEmail", label);
  };

  const exportHtml = () => {
    return new Promise((resolve, reject) => {
      if (emailEditorRef.current !== null) {
        emailEditorRef.current.editor.exportHtml((data) => {
          if (data) {
            const { html, design } = data;
            resolve({ html, design });
          } else {
            reject(new Error("Export HTML data is missing"));
          }
        });
      } else {
        reject(new Error("Email editor is not available"));
      }
    });
  };
  const handleSaveAndExit = async (operation) => {
    setHtmlLoading(true);
    if (operation === "saveAndExit") {
      try {
        const { html, design } = await exportHtml();
        setFieldValue("content", JSON.stringify({ html, design }));
        const formErrors = await validateForm();
        if (Object.keys(formErrors).length > 0) {
          setHasErrors(true);
          const firstErrorField = Object.keys(formErrors)[0];
          setFieldWithError(firstErrorField);
        } else {
          setShouldSubmit(true);
          setHasErrors(false);
          setFieldWithError("");
        }

        setHtmlLoading(false);
      } catch (error) {
        setFieldWithError("editor");
        setHtmlLoading(false);
      }
    } else {
      try {
        const { html, design } = await exportHtml();
        setFieldValue("content", JSON.stringify({ html, design }));
        const formErrors = await validateForm();
        if (Object.keys(formErrors).length > 0) {
          setHasErrors(true);
          const firstErrorField = Object.keys(formErrors)[0];
          setFieldWithError(firstErrorField);
        } else {
          setShouldSubmit(true);
          setHasErrors(false);
          setFieldWithError("");
        }

        setHtmlLoading(false);
      } catch (error) {
        setFieldWithError("editor");
        setHtmlLoading(false);
      }
    }
  };

  const handleAddMergeTag = async (tag) => {
    if (!Done) {
      return;
    }
    await setMergeTag(tag);
    console.log("tag", MergeTag);
    Done(tag);
  };

  const onReady = () => {
    // emailEditorRef?.current?.editor
    emailEditorRef.current.editor.registerCallback(
      "mergeTag",
      function (data, done) {
        setDone(() => (finalMergeTag) => {
          done(finalMergeTag);
        });
      }
    );
    if (content) {
      const parsedContent = JSON.parse(content);
      emailEditorRef.current.editor.loadDesign(parsedContent.design);
    }
    setFieldWithError("");
  };

  useEffect(() => {
    if (!active) {
      setFieldValue("sendImmediate", false);
      return;
    }
    setFieldValue("sendImmediate", active);
  }, []);

  useEffect(() => {
    const fieldsToUpdate = [];
    const objectKeysArray = Object.keys(errors);
    if (objectKeysArray.length > 0) {
      objectKeysArray.forEach((key) => {
        fieldsToUpdate.push(key);
      });
    } else {
      setFieldErrors([]);
    }

    if (fieldsToUpdate.length > 0) {
      setFieldErrors(fieldsToUpdate);
    }
  }, [touched, errors]);

  useEffect(() => {
    if (!isLoadingUser && userData?.data) {
      const preparedUsers = userData?.data.map((user) => {
        return {
          id: user.id,
          label: user?.username || user?.email || "",
        };
      });

      setUsers(preparedUsers);
    }
  }, [isLoadingUser, userData?.data]);

  useEffect(() => {
    if (shouldSubmit) {
      submitForm();
      setShouldSubmit(false);
    }
  }, [shouldSubmit, submitForm]);
  return (
    <Form>
      <div className="accordionWrapBox">
        <Accordion
          status
          headerTitle="Content Params"
          error={
            fieldErrors.includes("name") ||
            fieldErrors.includes("subject") ||
            fieldErrors.includes("replyTo")
              ? true
              : false
          }
        >
          <Box
            className="accordion-content"
            sx={{
              background: "#EDEDED",
              padding: "2rem",
            }}
          >
            <Box className="section">
              <Box className="section_one">
                <Field
                  name="name"
                  component={Input}
                  label="Email Template Name *"
                  error={errors?.name && touched?.name}
                  helperText={errors?.name}
                />
                <Field
                  name="subject"
                  component={Input}
                  label="Email Subject Line *"
                  error={errors?.subject && touched?.subject}
                  helperText={errors?.subject}
                />
              </Box>
            </Box>
            <Grid container spacing={6} className="section">
              <Grid xs={6} item>
                <Field
                  name="type"
                  component={Select}
                  defaultValue={{ value: type }}
                  label="Choose Template Type *"
                  options={options}
                  error={errors?.type && touched?.type}
                  helperText={errors?.type}
                />
              </Grid>
              <Grid xs={6} item>
                {type === "EVENT" && (
                  <Field
                    label="Choose event for comms"
                    name="livestream"
                    component={Autocomplete}
                    isEditEmail
                    onChange={(value) => {
                      handleChangeSearch({ label: value?.label });
                      setFieldValue("livestream", {
                        label: value?.label,
                        id: value?.id,
                      });
                    }}
                    options={events ? events : []}
                  />
                )}
                {type === "USER" && (
                  <Field
                    label="Select User"
                    placeholder="Search by Email"
                    name="userEmail"
                    component={Autocomplete}
                    onChange={handleChengeUserAutocomplite}
                    options={users.length ? users : []}
                    isEditEmail
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6} className="section">
              <Grid xs={6} item>
                <Field
                  name="senderEmail"
                  component={Input}
                  label="From Sender Email Address"
                />
                <Field name="replyTo" component={Input} label="Reply to" />
              </Grid>
              <Grid xs={6} item>
                <Field
                  name="senderName"
                  component={Input}
                  label="From Sender Name"
                />
              </Grid>
            </Grid>
          </Box>
        </Accordion>
        <Accordion
          status
          headerTitle="Content Editor"
          error={fieldWithError === "editor" ? true : false}
        >
          <Box
            className="accordion-content"
            sx={{
              background: "#EDEDED",
              padding: "2rem",
            }}
          >
            <Box className="section">
              {typeof emailEditorRef !== "undefined" && (
                <ForwardedEmailEditor
                  name="content"
                  editorId="editor-container"
                  ref={emailEditorRef}
                  onReady={onReady}
                  displayMode={"web"}
                  projectId={PidState}
                  options={{
                    user: {
                      id: 1,
                    },
                    mergeTags: MergeTagsMockDB,
                  }}
                />
              )}
            </Box>
           
          </Box>
        </Accordion>
        <Accordion
          status
          noBorderBottom
          headerTitle="Segment & Schedule"
          error={
            active === false &&
            (fieldErrors.includes("sendOnDate") ||
              fieldErrors.includes("sendOnTime"))
              ? true
              : false
          }
        >
          <Box
            className="accordion-content"
            sx={{
              padding: "2rem",
              background: "#EDEDED",
            }}
          >
            <Box className="section">
              <Box
                className="segment_top"
                sx={{
                  padding: "1rem 0",
                }}
              >
                <Typography variant="h5">Send Immediately</Typography>
                <Box className="segment_top_right">
                  <ToggleSquare
                    onClick={handleSendImmediately}
                    active={active}
                    leftLabel="YES"
                    rightLabel="NO"
                  />
                  <Box className="recipients">
                    <Typography variant="h5">Total Recipients</Typography>
                    <Typography variant="h3">0</Typography>
                  </Box>
                </Box>
              </Box>
              {active==="NO" && (
                <Box className="segment_bottom">
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        maxWidth: "45%",
                      }}
                    >
                      <label className="time-label">Send on Date *</label>
                      <Field
                        name="sendOnDate"
                        component={BasicDatePicker}
                        error={errors.sendOnDate && touched.sendOnDate}
                        helperText={errors.sendOnDate}
                      />
                    </Box>
                    <Box
                      sx={{
                        maxWidth: "45%",
                        marginLeft: "30px",
                      }}
                    >
                      <label className="time-label">Send Time *</label>
                      <Field
                        name="sendOnTime"
                        component={BasicTimePicker}
                        error={errors.sendTime && touched.sendTime}
                        helperText={errors.sendTime}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Accordion>
      </div>
      <Box
        sx={{
          marginTop: "80px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Button
          isLoading={htmlLoading}
          onClick={() => handleSaveAndExit("saveAndExit")}
          type="button"
        >
          Save & Exit
        </Button>
        <Button
          onClick={() => handleSaveAndExit("scheduleAndSend")}
          type="button"
          isModal
          isLoading={htmlLoading}
          disabled={fieldErrors.length > 0 || fieldWithError.length > 0}
        >
          Schedule & Send
        </Button>
      </Box>
    </Form>
  );
};

export default EditEmailForm;
