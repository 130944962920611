import { Box } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInput = ({ field, form, label, subText, error, helperText, defaultValue }) => (
  <Box>
    <label className='wrapperInputBlock__label'>
      {label}
    </label>
    <ReactPhoneInput
      country={'us'}
      onChange={(value) => form.setFieldValue(field?.name, value)}
      containerStyle={{
        marginTop: '.3rem',
        height: '6rem'
      }}
      value={defaultValue}
      inputStyle={{
        background: 'none',
        width: '100%',
        fontSize: '1.8rem',
        height: '6rem'
      }}
    />
  </Box>
)

export default PhoneInput;