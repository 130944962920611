import './StylesActionListItem.scss';

const ActionListItem = ({ item, user, handleClose }) => (
  <li className='ActionListItem' onClick={() => {
      item.action(user?.id)
      handleClose()
  }}>
    <div className='ActionListItem__wrapper--img'>
      <img src={item.icon} alt={item.icon}/>
    </div>
    <span className='ActionListItem__content--label'>
      {item.label}
    </span>
  </li>
)

export default ActionListItem;