import * as Yup from "yup"

//TODO need to correct validation
export const event = Yup.object()
  .shape({
    title: Yup.string().required("This field is required!"),
    startDay: Yup.string()
      .required("This field is required")
      .when("livestreamEnabled", {
        is: true,
        then: () => Yup.string().required("This field is required"),
        otherwise: () => Yup.string().strip(),
      }),
    startMonth: Yup.string()
      .required("This field is required")
      .when("livestreamEnabled", {
        is: true,
        then: () => Yup.string().required("This field is required"),
        otherwise: () => Yup.string().strip(),
      }),
    startYear: Yup.string()
      .required("This field is required")
      .when("livestreamEnabled", {
        is: true,
        then: () => Yup.string().required("This field is required"),
        otherwise: () => Yup.string().strip(),
      }),
    endDay: Yup.string()
      .required("This field is required")
      .when("livestreamEnabled", {
        is: true,
        then: () => Yup.string().required("This field is required"),
        otherwise: () => Yup.string().strip(),
      }),
    endMonth: Yup.string()
      .required("This field is required")
      .when("livestreamEnabled", {
        is: true,
        then: () => Yup.string().required("This field is required"),
        otherwise: () => Yup.string().strip(),
      }),
    endYear: Yup.string()
      .required("This field is required")
      .when("livestreamEnabled", {
        is: true,
        then: () => Yup.string().required("This field is required"),
        otherwise: () => Yup.string().strip(),
      }),
    startTime: Yup.string()
      .required("This field is required")
      .when("livestreamEnabled", {
        is: true,
        then: () => Yup.string().required("This field is required"),
        otherwise: () => Yup.string().strip(),
      }),
    endTime: Yup.string()
      .required("This field is required")
      .when("livestreamEnabled", {
        is: true,
        then: () => Yup.string().required("This field is required"),
        otherwise: () => Yup.string().strip(),
      }),
    livestreamEnabled: Yup.boolean().nullable(),
    rights: Yup.string()
      .oneOf(["africa", "nigeria", "global"])
      .required("This field is required!"),
    location: Yup.string().required("This field is required!"),
    venue: Yup.string().required("This field is required!"),
    printUrl: Yup.string().nullable().url(),
    chatUrl: Yup.string().url().nullable(),
    streamUrl: Yup.string().url().nullable(),
    eventMode: Yup.string()
      .oneOf(["LIVESTREAM", "ON_DEMAND", "LIVESTREAM_AND_ON_DEMAND"])
      .required("This field is required"),
    musicUrl: Yup.string().url().nullable(),
    vibes: Yup.string()
      .oneOf(["africa_world", "x-live", "nxt_up"])
      .required("This field is required!"),
    bannerUrl: Yup.string().url().nullable(),
    heroSectionTitle: Yup.string().nullable(),
    heroSectionDescription: Yup.string().nullable(),
    heroSectionUrls: Yup.array().of(Yup.string().url()),
    videoCarouselTitle: Yup.string().nullable(),
    videoCarouselDescription: Yup.string().nullable(),
    passCoverUrl: Yup.string().url(),
    topSenderTitle: Yup.string().url(),
    topSenderIcon: Yup.string().url(),
    videoCarouselUrls: Yup.array().of(
      Yup.object().shape({
        title: Yup.string(),
        url: Yup.string().url(),
        cover: Yup.string().url(),
      })
    ),
    artistGalleryDescription: Yup.string().nullable(),
    artistGalleryUrls: Yup.array().of(Yup.string().url()),
    eventVisionHeaderFirst: Yup.string().nullable(),
    eventVisionHeaderSecond: Yup.string().nullable(),
    eventVisionHeaderDescription: Yup.string().nullable(),
    eventVisionHeaderUrls: Yup.array().of(Yup.string().url()),
    eventVisionSponsors: Yup.array().of(Yup.string().url()),
    passes: Yup.array()
      .min(0)
      .max(3)
      .of(
        Yup.object().shape({
          isEnabled: Yup.boolean(),
          type: Yup.string()
            .oneOf(["LIVESTREAM", "BACKSTAGE", "ON_DEMAND"])
          ,
          mode: Yup.string()
            .oneOf(["LIVESTREAM", "ON_DEMAND"])
            ,
          title: Yup.string().when("isEnabled", {
            is: true,
            then: () => Yup.string().required("This field is required!"),
            otherwise: () => Yup.string().strip(),
          }),
          description: Yup.string().when("isEnabled", {
            is: true,
            then: () => Yup.string().required("This field is required!"),
            otherwise: () => Yup.string().strip(),
          }),
          eventPageDescription: Yup.string().nullable(),
          passCoverUrl: Yup.string()
            .url()
            .when("isEnabled", {
              is: true,
              then: () => Yup.string().required("This field is required"),
              otherwise: () => Yup.string().strip(),
            }),
          streamUrl: Yup.string()
            .url()
            .when("isEnabled", {
              is: true,
              then: () =>
                Yup.string().url().required("Streaming link is required"),
              otherwise: () => Yup.string().strip(),
            }),
          mtnServiceId: Yup.string()
            .url()
            .when("allowMTN", {
              is: true,
              then: () => Yup.string().required("This is a required field"),
              otherwise: () => Yup.string().strip(),
            }),
          videoContent: Yup.array().of(
            Yup.object().shape({
              videoTitle: Yup.string().nullable(),
              videoUrl: Yup.string().url().nullable(),
              videoDescription: Yup.string().nullable(),
              videoCoverArt: Yup.string().nullable(),
            })
          ),
          maxPerUser: Yup.number().when("isEnabled", {
            is: true,
            then: () => Yup.number().required("This field is required!"),
            otherwise: () => Yup.number().strip(),
          }),

          enableUSD: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean(),
            otherwise: () => Yup.number().strip(),
          }),
          priceUSD: Yup.number().when("enableUSD", {
            is: true,
            then: () => Yup.number().required("Price in Dollars"),
            otherwise: () => Yup.number().strip(),
          }),

          enableGBP: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean(),
            otherwise: () => Yup.number().strip(),
          }),
          priceGBP: Yup.number().when("enableGBP", {
            is: true,
            then: () => Yup.number().required("Price in Pounds"),
            otherwise: () => Yup.number().strip(),
          }),

          enableNGN: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean(),
            otherwise: () => Yup.number().strip(),
          }),
          priceNGN: Yup.number().when("enableNGN", {
            is: true,
            then: () => Yup.number().required("Price in Naira"),
            otherwise: () => Yup.number().strip(),
          }),

          enableMTN: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean(),
            otherwise: () => Yup.number().strip(),
          }),
          priceMTN: Yup.number().when("enableMTN", {
            is: true,
            then: () => Yup.number().required("MTN price"),
            otherwise: () => Yup.number().strip(),
          }),

          allowStripe: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean().required("This field is required!"),
            otherwise: () => Yup.boolean().strip(),
          }),
          allowPaystack: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean().required("This field is required!"),
            otherwise: () => Yup.boolean().strip(),
          }),
          allowMTN: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean().required("This field is required!"),
            otherwise: () => Yup.boolean().strip(),
          }),
        })
      ),
    onDemandPasses: Yup.array()
      .min(0)
      .max(3)
      .of(
        Yup.object().shape({
          isEnabled: Yup.boolean(),
          type: Yup.string()
            .oneOf(["LIVESTREAM", "BACKSTAGE", "ON_DEMAND"])
          ,
          mode: Yup.string()
            .oneOf(["LIVESTREAM", "ON_DEMAND"])
            ,
          title: Yup.string().when("isEnabled", {
            is: true,
            then: () => Yup.string().required("This field is required!"),
            otherwise: () => Yup.string().strip(),
          }),
          description: Yup.string().when("isEnabled", {
            is: true,
            then: () => Yup.string().required("This field is required!"),
            otherwise: () => Yup.string().strip(),
          }),
          eventPageDescription: Yup.string().nullable(),
          passCoverUrl: Yup.string()
            .url()
            .when("isEnabled", {
              is: true,
              then: () => Yup.string().url().required("This field is required"),
              otherwise: () => Yup.string().strip(),
            }),
          streamUrl: Yup.string()
            .url()
            .when("isEnabled", {
              is: true,
              then: () =>
                Yup.string().url().required("On-demand link is required"),
              otherwise: () => Yup.string().strip(),
            }),
          mtnServiceId: Yup.string()
            .url()
            .when("allowMTN", {
              is: true,
              then: () => Yup.string().required("This is a required field"),
              otherwise: () => Yup.string().strip(),
            }),
          videoContent: Yup.array().of(
            Yup.object().shape({
              videoTitle: Yup.string().nullable(),
              videoUrl: Yup.string().url().nullable(),
              videoDescription: Yup.string().nullable(),
              videoCoverArt: Yup.string().nullable(),
            })
          ),
          maxPerUser: Yup.number().when("isEnabled", {
            is: true,
            then: () => Yup.number().required("This field is required!"),
            otherwise: () => Yup.number().strip(),
          }),

          enableUSD: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean(),
            otherwise: () => Yup.number().strip(),
          }),
          priceUSD: Yup.number().when("enableUSD", {
            is: true,
            then: () => Yup.number().required("Price in Dollars"),
            otherwise: () => Yup.number().strip(),
          }),

          enableGBP: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean(),
            otherwise: () => Yup.number().strip(),
          }),
          priceGBP: Yup.number().when("enableGBP", {
            is: true,
            then: () => Yup.number().required("Price in Pounds"),
            otherwise: () => Yup.number().strip(),
          }),

          enableNGN: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean(),
            otherwise: () => Yup.number().strip(),
          }),
          priceNGN: Yup.number().when("enableNGN", {
            is: true,
            then: () => Yup.number().required("Price in Naira"),
            otherwise: () => Yup.number().strip(),
          }),

          enableMTN: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean(),
            otherwise: () => Yup.number().strip(),
          }),
          priceMTN: Yup.number().when("enableMTN", {
            is: true,
            then: () => Yup.number().required("MTN price"),
            otherwise: () => Yup.number().strip(),
          }),

          allowStripe: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean().required("This field is required!"),
            otherwise: () => Yup.boolean().strip(),
          }),
          allowPaystack: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean().required("This field is required!"),
            otherwise: () => Yup.boolean().strip(),
          }),
          allowMTN: Yup.boolean().when("isEnabled", {
            is: true,
            then: () => Yup.boolean().required("This field is required!"),
            otherwise: () => Yup.boolean().strip(),
          }),
        })
      ),
    isSprayMoneyEnabled: Yup.boolean().required("This field is required!"),
    sprayMoneyCurrencyName: Yup.string().when("isSprayMoneyEnabled", {
      is: true,
      then: () => Yup.string().required("This field is required!"),
      otherwise: () => Yup.string().strip(),
    }),
    sprayMoneyImageUrls: Yup.array()
      .of(Yup.string().url())
      .when("isSprayMoneyEnabled", {
        is: true,
        then: () => Yup.array().of(Yup.string().url()),
        otherwise: () => Yup.array().strip(),
      }),
    sprayMoneyCurrencyImage: Yup.string()
      .url()
      .when("isSprayMoneyEnabled", {
        is: true,
        then: () => Yup.string().url(),
        otherwise: () => Yup.string().strip(),
      }),
    sprayMoneyTopSenderTitle: Yup.string().when("isSprayMoneyEnabled", {
      is: true,
      then: () => Yup.string(),
      otherwise: () => Yup.string().strip(),
    }),
    sprayMoneyTopSenderIcon: Yup.string()
      .url()
      .when("isSprayMoneyEnabled", {
        is: true,
        then: () => Yup.string().strip(),
        otherwise: () => Yup.string().strip(),
      }),
    sprayMoneyAllowStripe: Yup.boolean().when("isSprayMoneyEnabled", {
      is: true,
      then: () => Yup.boolean().required("This field is required!"),
      otherwise: () => Yup.boolean().strip(),
    }),
    sprayMoneyAllowPaystack: Yup.boolean().when("isSprayMoneyEnabled", {
      is: true,
      then: () => Yup.boolean().required("This field is required!"),
      otherwise: () => Yup.boolean().strip("This field is required!"),
    }),
    sprayMoneyAllowMTN: Yup.boolean().when("isSprayMoneyEnabled", {
      is: true,
      then: () => Yup.boolean().strip(),
      otherwise: () => Yup.boolean().strip("This field is required!"),
    }),
    artistId: Yup.number().required("This field is required!"),
  })
  .required("This field is required!")
