import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";

import { emailValidate } from "../../../validates/emailValidate";
import {
  createEmail,
  getEmailTemplatesById,
  editEmail,
} from "../../../api/emails/emails";
import { getEvents } from "../../../api/events";
import { LIMIT } from "../../../constants";
import useDebounce from "../../../hooks/useDebounce";
import Page from "../../../components/common/page/Page";
import EditEmailForm from "../../../forms/editEmailForm/EditEmailForm";
import FormWrapper from "../../../components/common/formWrapper/FormWrapper";
import { useNavigate } from "react-router-dom";
import EditorFormWrap from "../../../components/common/formWrapper/EditorFormWrap";
const EditEmailPage = () => {
  const [templateId, setTemplateId] = useState(null);
  const [search, setSearch] = useState("");
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const debouncedSearchTerm = useDebounce(search, 300);

  const {
    isLoading: isLoadingEvent,
    isError: isErrorEvent,
    data: eventData,
    refetch,
  } = useQuery({
    retry: 0,
    queryKey: ["events"],
    queryFn: () => getEvents({ page: 1, limit: LIMIT, search }),
  });

  useEffect(() => {
    if (!isLoadingEvent) {
      setEvents(() => {
        return eventData?.data.map(({ title, id }) => {
          return {
            label: title,
            id,
          };
        });
      });
    }
  }, [isLoadingEvent]);

  const { isLoading, isError, isSuccess, data, mutate, error } = useMutation(
    ({ emailData }) => createEmail({ emailData })
  );

  const {
    isLoading: isEditLoading,
    isError: isEditError,
    isSuccess: isEditSuccess,
    data: isDataEditGetEmailTemplateId,
    mutate: mutateEditEmail,
    error: isEditErrors,
  } = useMutation((emailData) => editEmail(templateId, emailData), {
    onSuccess: (data) => {
      console.log("Edit success:", data);
      navigate("/comms");
    },
  });

  const { data: dataGetTemplateById, isLoading: isLoadingGetTemplateById } =
    useQuery(
      ["email-template-Id", templateId],
      () => getEmailTemplatesById({ templateId }),
      {
        enabled: !!templateId,
      }
    );

  useEffect(() => {
    refetch();
    setEvents(() => {
      return eventData?.data.map(({ title, id }) => {
        return {
          label: title,
          id,
        };
      });
    });
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      const successData = data?.message;
      enqueueSnackbar(successData, { variant: "success" });
    }

    if (!isLoading && isError) {
      const erorrData = error?.response?.data?.data;
      erorrData?.forEach(({ field, message }) =>
        enqueueSnackbar(`${field ? field + ":" : ""}${message}`, {
          variant: "error",
        })
      );
    }
  }, [isLoading, isError]);

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const id = params.get("id");

    if (id) {
      setTemplateId(parseInt(id));
    }
  }, []);

  const handleChangeSearch = ({ label }) => {
    setSearch(label)
    console.log("serach", label)
  };

  const handleSubmit = (formValue) => {
    const millisecondsSendDate = new Date(formValue?.["sendOnDate"]).getTime();
    const millisecondsSendTime = new Date(formValue?.["sendOnTime"]).getTime();

    const emailData = {
      ...formValue,
      sendOnDate: millisecondsSendDate,
      sendOnTime: millisecondsSendTime,
    };

    if (templateId) {
      // Edit operation
      mutateEditEmail(emailData);
    } else {
      // Create operation
      mutate(
        { emailData },
        {
          onSuccess: (data) => {
            console.log("Create success:", data);
            navigate("/comms");
          },
        }
      );
    }
  };

  return (
    <Page title="Add/Edit Email">
      <EditorFormWrap>
        {!isLoadingGetTemplateById && (
          <Formik
            initialValues={{
              name: dataGetTemplateById?.data?.name,
              userEmail: dataGetTemplateById?.data?.userEmail,
              type: dataGetTemplateById?.data?.type || "EVENT",
              subject: dataGetTemplateById?.data?.subject,
              senderEmail: dataGetTemplateById?.data?.senderEmail,
              senderName: dataGetTemplateById?.data?.senderName,
              replyTo: dataGetTemplateById?.data?.replyTo,
              livestream: dataGetTemplateById?.data?.event
                ? {
                    label: dataGetTemplateById?.data?.event.title,
                    id: dataGetTemplateById?.data?.event.id,
                  }
                : dataGetTemplateById?.data?.eventId,
              content: dataGetTemplateById?.data?.content,
              status: dataGetTemplateById?.data?.status,
              sendOnDate: dataGetTemplateById?.data?.sendOnDate,
              sendOnTime: dataGetTemplateById?.data?.sendOnTime,
              sendImmediate: false,
              templateId: templateId,
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={emailValidate}
          >
            {({ errors, touched }) => {
              return (
                <EditEmailForm
                  handleChangeSearch={handleChangeSearch}
                  events={events}
                  errors={errors}
                  touched={touched}
                  isLoading={isLoading}
                />
              );
            }}
          </Formik>
        )}
      </EditorFormWrap>
    </Page>
  );
};

export default EditEmailPage;
