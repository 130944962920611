import {useState, useEffect, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Box, TableRow } from '@mui/material';

import { getArtist } from '../../api/artist';
import { LIMIT } from '../../constants';
import { getEvents } from '../../api/events/index';
import { convertDate } from '../../utils/convertDate';
import Table from '../../components/table/Table';
import Page from '../../components/common/page/Page';
import Button from '../../components/common/button/Button';
import CustomTabelCell from '../../components/common/CustomTebleCell/CustomTableCell';
import DeactivateEventModal from '../../components/modals/deactivateEventModal/DeactivateEventModal';
import useDebounce from '../../hooks/useDebounce';
import ellipsisIcon from '../../assets/icons/ellipsis.png';
import ActionList from '../../components/actionsList/ActionList';
import editLiveStreamIcon from '../../assets/icons/editLiveStreamIcon.png';
import deleteLiveStreamIcon from '../../assets/icons/deleteEventIcon.png';
import checkCircleIcon from '../../assets/icons/checkCircleIcon.svg';
import errorCircleIcon from '../../assets/icons/errorCircleIcon.svg';
import TableFilter from '../../components/tableFilter/TableFIlter';

const tableHeads = [
  { label: 'Livestream' },
  { label: 'Start Time' },
  { label: 'End Time' },
  { label: 'Broadcast' },
  { label: 'StreamPass Type' },
  { label: 'Status' },
  { label: ellipsisIcon, isIcon: true },
];

const filterEventStatus = [
  { label: 'Livestream status', value: 'placeholder' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Deactivated', value: 'DEACTIVATED' }
]

const LiveStreams = () => {
  const navigator = useNavigate();
  const [isShowDeactivateModal, setIsShowDeactivateModal] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [filters, setFilters] = useState({});
  const [artist, setArtist] = useState([]);
  const [searchArtist, setSearchArtist] = useState('');
  const debouncedFilters = useDebounce(filters, 300);
  const debouncedSearchArtist = useDebounce(searchArtist, 300);

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });

  const {
    data: dataArtist,
    refetch: refetchArtist,
  } = useQuery({
    retry: 0,
    queryKey: ['artist'],
    queryFn: (() => getArtist({ s: searchArtist }))
  })

  const {
    data,
    error,
    refetch,
  } = useQuery({
    retry: 0,
    queryKey: ['livestream'],
    queryFn: (() => getEvents({ page: pagination.pageIndex, limit: LIMIT, search: filters?.search, artistId: filters?.artistId, status: filters?.status }))
  });


  const handleRefetchArtist = useCallback(() => {
    refetchArtist()
    // eslint-disable-next-line
  }, [refetchArtist])

  useEffect(() => {
    handleRefetchArtist()
    // eslint-disable-next-line
  }, [debouncedSearchArtist])

  useEffect(() => {
    if (dataArtist) {
      setArtist(() => {
        return dataArtist?.data.map((artist) => {
          return {
            label: artist?.name,
            id: artist?.id
          }
        })
      })
    }
  }, [dataArtist])

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [pagination.pageIndex, debouncedFilters])

  const handleEditLiveStream = ({ eventId }) => navigator(`/createNewLiveStream/?eventId=${eventId}`)
  const handleCloseDeactivateModal = () => setIsShowDeactivateModal(false);

  const handleChangeFilter = (field, event) => {
    const value = event.target.value;

    if (field === 'artistId') {
      setSearchArtist(value);
      const artistId = artist?.find(({ label }) => label === value);

      return setFilters((prevState) => {
        return {
          ...prevState,
          // eslint-disable-next-line
          ['artistId']: artistId?.id,
        }
      })
    }

    setFilters((prevState) => {
      return {
        ...prevState,
        [field]: value === 'placeholder' ? '' : value,
      }
    })
  }

  const handleOpenDeactivateModal = ({ eventId }) => {
    setEventId(eventId);
    setIsShowDeactivateModal(true);
  }

  return (
    <Page
      title='LiveStreams'
      action={
        <Button
          onClick={() => navigator('/createNewLiveStream?tab=eventInformation')}
        >
          Create event
        </Button>
      }
    >
      <Box>
      <TableFilter 
        autocompliteData={artist}
        autocomplitePlaceholder='Select artist'
        textFieldPlaceholder='Search for Livestreams'
        autocompliteTextName='artistId'
        handleFilters={handleChangeFilter}
        selectOptions={filterEventStatus}
        textFieldName='search'
        selectFieldName='status'
      />
        <Table
          error={error}
          headers={tableHeads}
          meta={data?.meta}
          pagination={pagination}
          setPagination={setPagination}
          page={pagination?.pageIndex}
        >
          {
            data?.data && data?.data.map(stream => {
              return (
                <TableRow
                  sx={{
                    background: '#FAFAFA 0% 0% no-repeat padding-box',
                    borderBottom: '7px solid white'
                  }}
                  key={stream?.id}
                >
                  <CustomTabelCell>
                    {stream?.title}
                  </CustomTabelCell>
                  <CustomTabelCell>
                    {convertDate(stream?.startAt, '-')}
                  </CustomTabelCell>
                  <CustomTabelCell>
                    {convertDate(stream?.endAt, '-')}
                  </CustomTabelCell>
                  <CustomTabelCell>
                    {stream?.location}
                  </CustomTabelCell>
                  <CustomTabelCell>
                    {stream?.streamPassType}
                  </CustomTabelCell>
                  <CustomTabelCell>
                    {
                      stream?.status === 'ACTIVE' ? (
                        <Box>
                          <img src={checkCircleIcon} alt='circle'/>
                        </Box>
                      ) : (
                        <Box>
                          <img src={errorCircleIcon} alt='circle'/>
                        </Box>
                      )
                    }
                  </CustomTabelCell>
                  <CustomTabelCell>
                    <ActionList
                      actions={[
                        { label: 'Edit Event', icon: editLiveStreamIcon, action: () => handleEditLiveStream({ eventId: stream.id })},
                        { label: 'Deactivate Event', icon: deleteLiveStreamIcon, action: () => handleOpenDeactivateModal({ eventId: stream.id }) },
                      ]}
                    />
                  </CustomTabelCell>
                </TableRow>
              )
            })
          }
        </Table>
      </Box>
      {
        isShowDeactivateModal &&
          <DeactivateEventModal
            eventId={eventId}
            refetch={refetch}
            handleClose={handleCloseDeactivateModal}
          />
      }
    </Page>
  )
}

export default LiveStreams;