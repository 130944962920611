import { fetchClient } from "../axiosinterceptor";

export const getUserEmails = async ({ userId }) => {
  const { data } = await fetchClient().get(`emails/${userId}`);

  return data;
};

export const resendUserEmail = async ({ userId }) => {
  const { data } = await fetchClient().post("/emails/resend", {
    ids: [userId],
  });

  return data;
};

export const deleteEmailTemplate = async ({ templateId }) => {
  return await fetchClient().delete(`/email-templates/${templateId}`);
};

export const getEmailTemplates = async ({
  page,
  limit,
  type,
  search,
  eventId,
}) => {
  const { data } = await fetchClient().get(
    `email-templates/limit/${limit}/page/${page}`,
    {
      params: {
        type,
        s: search,
        [eventId ? "eventId" : ""]: eventId,
      },
    }
  );

  return data;
};

export const duplicateEmailTemplate = async ({ templateId }) => {
  return await fetchClient().post(`email-templates/${templateId}/duplicate`);
};

export const getEmailTemplatesById = async ({ templateId }) => {
  const { data } = await fetchClient().get(`email-templates/${templateId}`);
  return data;
};

export const createEmail = async ({
  emailData: {
    name,
    type,
    subject,
    senderEmail,
    senderName,
    replyTo,
    livestream,
    content,
    status,
    sendImmediate,
    sendOnDate,
    sendOnTime,
    userEmail,
  },
}) => {
  let sendData = {
    name,
    type,
    subject,
    senderEmail,
    senderName,
    replyTo,
    eventId: livestream?.id,
    content,
    status: "ACTIVE",
    sendOnDate,
    sendImmediate,
    sendOnTime,
    userEmail,
  };


  if (type === "USER" || type === "MARKETING") {
    delete sendData?.eventId;
  }

  const { data } = await fetchClient().post("/email-templates", {
    ...sendData,
  });
  return data;
};


export const editEmail = async (templateId, {
  name,
  type,
  subject,
  senderEmail,
  senderName,
  replyTo,
  livestream,
  content,
  status,
  sendImmediate,
  sendOnDate,
  sendOnTime,
  userEmail,
}) => {

  let sendData = {
    name,
    type,
    subject,
    senderEmail,
    senderName,
    replyTo,
    eventId: livestream?.id,
    content,
    status: "ACTIVE",
    sendOnDate,
    sendImmediate,
    sendOnTime,
    userEmail,
  };


  if (type === "USER" || type === "MARKETING") {
    delete sendData?.eventId;
  }

  const { data } = await fetchClient().put(`/email-templates/${templateId}`, {
    ...sendData,
  });
  return data;
};
