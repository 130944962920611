import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Box, TableRow, TableCell } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { LIMIT } from "../../constants";
import { getAdminGroups } from "../../api/adminsGroups";
import { deactivateAdmin, getAdmins } from "../../api/admins";
import TableFilter from "../../components/tableFilter/TableFIlter";
import useDebounce from "../../hooks/useDebounce";
import Button from "../../components/common/button/Button";
import Page from "../../components/common/page/Page";
import ActionList from "../../components/actionsList/ActionList";
import ellipsisIcon from "../../assets/icons/ellipsis.png";
import Table from "../../components/table/Table";
import checkCircleIcon from "../../assets/icons/checkCircleIcon.svg";
import errorCircleIcon from "../../assets/icons/errorCircleIcon.svg";
import pendingCircleIcon from "../../assets/icons/iconPending.svg";

const adminsFilterStatus = [
  { label: "User status", value: "placeholder" },
  { label: "Active", value: "ACTIVE" },
  { label: "Pending", value: "PENDING" },
  { label: "Blocked", value: "DEACTIVATED" },
];

const AdminsPage = () => {
  const navigator = useNavigate();
  const [filters, setFilters] = useState();
  const [adminGroup, setAdminGroup] = useState([]);
  const [searchAdmin, setSearchAdmin] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });
  const debouncedFilters = useDebounce(filters, 300);
  const debouncedSearchAdmin = useDebounce(setSearchAdmin, 300);

  const { data: dataAdminGroup, refetch: refetchAdminGroup } = useQuery({
    queryKey: ["adminsGroup"],
    queryFn: () =>
      getAdminGroups({
        page: pagination.pageIndex,
        LIMIT,
        search: searchAdmin,
      }),
  });

  console.log("adgroup", dataAdminGroup);
  const { data, refetch, error } = useQuery({
    retry: 0,
    queryKey: ["admins"],
    queryFn: () =>
      getAdmins({
        page: pagination.pageIndex,
        limit: LIMIT,
        search: filters?.search,
        status: filters?.status,
        role: filters?.role,
      }),
  });
  console.log("admin", data);

  const {
    error: deactivateError,
    data: deactivateData,
    mutate: deactivateMutate,
    isLoading: deactivateLoading,
    isSuccess: deactivateIsSuccess,
  } = useMutation({
    mutationFn: async ({ id }) => await deactivateAdmin({ id }),
  });

  const handleCreateNewAdmin = () => navigator("/adminInfo");
  const handleEditUser = (userId) => navigator(`/adminInfo/?userId=${userId}`);

  const handleDeactivateUser = async ({ id }) => await deactivateMutate({ id });

  useEffect(() => {
    refetchAdminGroup();
  }, [debouncedSearchAdmin]);

  useEffect(() => {
    if (dataAdminGroup) {
      setAdminGroup(() => {
        return dataAdminGroup?.data.map((adminGroup) => {
          return {
            label: adminGroup?.name,
            id: adminGroup.id,
          };
        });
      });
    }
  }, [dataAdminGroup]);

  useEffect(() => {
    const deactivateErrorMsg = deactivateError?.response?.data?.message;
    if (deactivateErrorMsg) {
      enqueueSnackbar(deactivateErrorMsg, { variant: "error" });
    }

    if (!deactivateLoading && deactivateIsSuccess) refetch();
  }, [deactivateData, deactivateLoading, deactivateIsSuccess]);

  const pageActionButton = useMemo(
    () => <Button onClick={handleCreateNewAdmin}>Create Admin</Button>,
    []
  );

  const tableHeads = [
    { label: "First Name" },
    { label: "Last Name" },
    { label: "Email Address" },
    { label: "Phone Number" },
    { label: "Status" },
    { label: "Member Type" },
    { label: ellipsisIcon, isIcon: true },
  ];

  const handleChangeFilter = (field, event) => {
    const value = event.target.value;
    if (field === "role") {
      setSearchAdmin(value);
      const adminGroupId = adminGroup?.find(({ label }) => label === value);

      return setFilters((prevState) => {
        return {
          ...prevState,
          ["role"]: adminGroupId?.id,
        };
      });
    }

    setFilters((prevState) => {
      return {
        ...prevState,
        [field]: value === "placeholder" ? "" : value,
      };
    });
  };

  const CustomTabelCell = ({ children }) => {
    return (
      <TableCell
        sx={{
          fontSize: "1.8rem",
          fontFamily: "BaguedeFree-Regular",
          align: "center",
        }}
        component="th"
        scope="row"
      >
        {children}
      </TableCell>
    );
  };

  useEffect(() => {
    refetch();
  }, [pagination.pageIndex, debouncedFilters]);

  return (
    <Page title="System Admins" action={pageActionButton}>
      <TableFilter
        autocompliteData={adminGroup}
        autocompliteTextName="role"
        textFieldPlaceholder="Search for admin"
        selectFieldName="status"
        textFieldName="search"
        selectOptions={adminsFilterStatus}
        handleFilters={handleChangeFilter}
        autocomplitePlaceholder="User  Group"
      />
      <Table
        pagination={pagination}
        setPagination={setPagination}
        page={pagination?.pageIndex}
        meta={data?.meta}
        headers={tableHeads}
        error={error}
      >
        {data?.data.map((admin) => {
          console.log("admij", admin);
          return (
            <TableRow>
              <CustomTabelCell>{admin?.firstName}</CustomTabelCell>
              <CustomTabelCell>{admin?.lastName}</CustomTabelCell>
              <CustomTabelCell>{admin?.email}</CustomTabelCell>
              <CustomTabelCell>{admin?.phoneNumber}</CustomTabelCell>
              <CustomTabelCell>
                {admin?.status === "ACTIVE" ? (
                  <Box>
                    <img src={checkCircleIcon} alt="circle" />
                  </Box>
                ) : admin?.status === "PENDING" ? (
                  <Box>
                    <img src={pendingCircleIcon} alt="circle" />
                  </Box>
                ) : (
                  <Box>
                    <img src={errorCircleIcon} alt="circle" />
                  </Box>
                )}
              </CustomTabelCell>
              <CustomTabelCell>
                {admin?.roleId === 1
                  ? "SuperAdmin"
                  : admin?.roleId === 2
                  ? "User"
                  : admin?.roleId === 3
                  ? "Admin"
                  : "Test"}
              </CustomTabelCell>
              <CustomTabelCell>
                <ActionList
                  actions={[
                    {
                      label: "Edit User",
                      icon: "",
                      action: () => handleEditUser(admin?.id),
                    },
                    {
                      label: "Deactivate User",
                      icon: "",
                      action: async () =>
                        await handleDeactivateUser({ id: admin?.id }),
                    },
                  ]}
                />
              </CustomTabelCell>
            </TableRow>
          );
        })}
      </Table>
    </Page>
  );
};

export default AdminsPage;
