import { Field } from "formik";

import Input from "../../../components/common/input/Input";
import "./StylesPurchaseInformation.scss";
import { Box, Typography } from "@mui/material";
import UploadEventPrint from "../../../components/common/uploadEventPrint/UploadEventPrint";

const StreamPurchaseInformationForm = ({
  passIndex,
  passes,
  tmpPreview,
  setTmpPreview,
}) => {
  return (
    <div className="StreamPurchaseInformationForm">
      <div className="StreamPurchaseInformationForm__form">
        <Box style={{ margin: "2px 0 8px 0" }}>
          <Typography variant="h6">Stream Pass Cover</Typography>
          <UploadEventPrint
            type="vertical"
            label={`Pass Cover`}
            subText="Image will be resized to width: 499px, height: 722px"
            blockName="passes"
            fieldName={`passes[${passIndex}].passCoverUrl`}
            tmpPreview={tmpPreview}
            setTmpPreview={setTmpPreview}
            item={passes[passIndex]?.passCoverUrl}
          />
        </Box>
        <div className="StreamPurchaseInformationForm__form__wrapper__checkbox--group">
          <span className="StreamPurchaseInformationForm__form__checkbox--group--label">
            This Stream Pass should be available to purchase in?
          </span>
          <div className="StreamPurchaseInformationForm__form__checkbox--group">
            <label className="formikCheckBox">
              <Field
                type="checkbox"
                name={`passes[${passIndex}].enableNGN`}
                checked={passes?.[passIndex]?.enableNGN}
              />
              NGN
            </label>
            <label className="formikCheckBox">
              <Field
                type="checkbox"
                name={`passes[${passIndex}].enableGBP`}
                checked={passes?.[passIndex]?.enableGBP}
              />
              GBP
            </label>
            <label className="formikCheckBox">
              <Field
                type="checkbox"
                name={`passes[${passIndex}].enableUSD`}
                checked={passes?.[passIndex]?.enableUSD}
              />
              USD
            </label>
            <label className="formikCheckBox">
              <Field
                type="checkbox"
                name={`passes[${passIndex}].enableMTN`}
                checked={passes?.[passIndex]?.enableMTN}
              />
              MTN
            </label>
          </div>
          <div className="StreamPurchaseInformationForm__form__wrapper__input--group">
            {/* <span className="StreamPurchaseInformationForm__form__checkbox--group--label">
              Allow Payment Methods
            </span> */}
            <div className="StreamPurchaseInformationForm__form__input--group">
                <Field
                  component={Input}
                  disabled={!passes[passIndex]?.enableNGN}
                  label="Price in NGN"
                  name={`passes[${passIndex}].priceNGN`}
                  subText="Per Stream Pass"
                  defaultValue={passes?.[passIndex]?.priceNGN}
                />
              <Field
                component={Input}
                disabled={!passes[passIndex]?.enableGBP}
                label="Price in GBP"
                name={`passes[${passIndex}].priceGBP`}
                subText="Per Stream Pass"
                defaultValue={passes?.[passIndex]?.priceGBP}
              />
              <Field
                component={Input}
                disabled={!passes[passIndex]?.enableUSD}
                label="Price in USD"
                name={`passes[${passIndex}].priceUSD`}
                subText="Per Stream Pass"
                defaultValue={passes?.[passIndex]?.priceUSD}
              />
              <Field
                component={Input}
                disabled={!passes[passIndex]?.enableMTN}
                label="Price in MTN"
                name={`passes[${passIndex}].priceMTN`}
                subText="Per Stream Pass"
                defaultValue={passes?.[passIndex]?.priceMTN}
              />
            </div>
          </div>
          <div className="StreamPurchaseInformationForm__form__wrapper__input--group">
            <span className="StreamPurchaseInformationForm__form__checkbox--group--label">
              Allow Payment Methods
            </span>
            <div className="StreamPurchaseInformationForm__form__input--group">
              <label className="formikCheckBox">
                <Field
                  type="checkbox"
                  name={`passes[${passIndex}].allowStripe`}
                  checked={passes?.[passIndex]?.allowStripe}
                />
                Stripe
              </label>
              <label className="formikCheckBox">
                <Field
                  type="checkbox"
                  name={`passes[${passIndex}].allowPaystack`}
                  checked={passes?.[passIndex]?.allowPaystack}
                />
                Paystack
              </label>
              <label className="formikCheckBox">
                <Field
                  type="checkbox"
                  name={`passes[${passIndex}].allowMTN`}
                  checked={passes?.[passIndex]?.allowMTN}
                />
                MTN
              </label>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Field
              component={Input}
              label="MTN Service ID"
              name={`passes[${passIndex}].mtnServiceId`}
              defaultValue={passes?.[passIndex]?.mtnServiceId}
              disabled={!passes[passIndex]?.allowMTN}
            />
          </div>
          <div className="StreamPurchaseInformationForm__form__wrapper__input--group">
            <span
              style={{ marginTop: "10px" }}
              className="StreamPurchaseInformationForm__form__checkbox--group--label"
            >
              User Purchase Limit from store
            </span>
            <div className="StreamPurchaseInformationForm__form__input--group">
              <Field
                component={Input}
                label="Per User Account"
                name={`passes[${passIndex}].maxPerUser`}
                defaultValue={passes?.[passIndex]?.maxPerUser}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StreamPurchaseInformationForm;
