import './StylesSprayMoneyBalance.scss';

const SprayMoneyBalance = ({ balance, handleShowUpdateWalletModal, title, isArtist }) => (
  <div className='SprayMoneyBalance'>
    <div className='SprayMoneyBalance__top'>
      <span>{title}</span>
    </div>
    <div className='SprayMoneyBalance__content'>
      <span>{!isArtist ? `N ${balance ? balance : 0}` : 0}</span>
    </div>
    {
      !isArtist &&
      <div className='SprayMoneyBalance__action'>
        <button
          onClick={handleShowUpdateWalletModal}
        >
          Top up
        </button>
      </div>
    }
  </div>
)

export default SprayMoneyBalance;