export const convertDate = (date, separator = '-') => {
  let date_ = new Date(date);

  if (typeof date === 'string' && date.includes('/')) {
    const splitDate = date.split('/');
    const year = splitDate[2];
    const month = splitDate[1];
    const day = splitDate[0];

    date_ = new Date(year, month, day);
  }

  let year = date_.getFullYear();
  let month = date_.getMonth() + 1;
  let day = date_.getDate();

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return `${day}${separator}${month}${separator}${year}`
}

