import ToggleSwitch from '../toggleSwitch/ToggleSwitch';
import './StylesBlockWithToggle.scss';

const BlockWithToggle = ({ title, children, name }) => (
  <div className='BlockWithToggle'>
    <div className='BlockWithToggle__header'>
      <span>{title}</span>
      <ToggleSwitch name={name}/>
    </div>
    <div className='BlockWithToggle__content'>
      {children}
    </div>
  </div>
)

export default BlockWithToggle;