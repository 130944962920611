import {useCallback, useEffect, useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {Formik} from 'formik';
import {enqueueSnackbar} from 'notistack';

import {createNewUserGroups, updateUserGroup} from '../../api/userGropup';
import {getPermission} from '../../api/permission';
import Page from '../../components/common/page/Page';
import FormWrapper from '../../components/common/formWrapper/FormWrapper';
import AddEditAdminGroupForm from '../../forms/adminsForms/AddEditAdminGroupForm';
import {createAdminGroupValidate} from "../../validates/createAdminGroup";
import {Navigate, useNavigate} from "react-router-dom";

const CreateEditNewAdminGroupPage = () => {
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [fields, setFields] = useState({});
  const [isNewGroup, setIsNewGroup] = useState(false);

  const handleSuccessRequest = useCallback((data) => {
    enqueueSnackbar(data?.message, {variant: 'success'});
    navigate('/adminGroups')
  }, [navigate, enqueueSnackbar])

  const handleFailedRequest = useCallback((data) => {
    enqueueSnackbar(data?.response?.data?.message, {variant: 'error'});
  }, [enqueueSnackbar])

  const {
    isLoading,
    isError,
    data,
  } = useQuery({
    queryKey: ['permissions'],
    queryFn: () => getPermission(),
  });

  const {
    isLoading: isLoadingCreate,
    mutate,
  } = useMutation(({permissions, status, name, identifier}) =>
    createNewUserGroups({permissions, status, name, identifier}));

  const {
    isLoading: isLoadingUpdate,
    mutate: updateMutate,
  } = useMutation(({id, permissions, status, name, identifier}) =>
    updateUserGroup({id, permissions, status, name, identifier}));

  const handleSubmit = (values) => {
    const selectedPermissionsKeys = Object.keys(values).filter((key) => values[key]);

    const ids = data?.data.reduce((acc, currentValue) => {
      selectedPermissionsKeys.forEach((key) => {
        if (currentValue.name === key) {
          acc.push(currentValue?.id);
        }
      })

      return acc;
    }, [])

    const params = new URL(window.location).searchParams;
    const id = params.get('groupId');

    if (id) {
      return updateMutate({
        id,
        permissions: ids,
        status: values?.status,
        name: values?.name,
        identifier: values?.identifier
      },
        {
          onSuccess: handleSuccessRequest,
          onError: handleFailedRequest
        }
        );
    }

    mutate(
      {
        permissions: ids,
        status: values?.status,
        name: values?.name,
        identifier: values?.identifier
      },
      {
        onSuccess: handleSuccessRequest,
        onError: handleFailedRequest
      }
    );
  }

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const id = params.get('groupId');

    if (id) setIsNewGroup(true)
  }, [])


  useEffect(() => {

    if (!isLoading && !isError && data?.data.length) {
      const result = data?.data.reduce((acc, currentValue) => {
        if (!currentValue?.type) return acc;

        const type = currentValue?.type;

        if (acc[type]) {
          acc[type].push(currentValue);
        } else {
          acc[type] = [currentValue];
        }

        setFields((prevState) => {
          return {
            ...prevState,
            [currentValue?.name]: false,
          }
        })

        return acc;
      }, {})

      setPermissions(result);
    }
  }, [isLoading, isError, data])


  return (
    <Page title='Add/Edit Admin'>
      <FormWrapper>
        {
          Object.keys(fields).length &&
          <Formik
            initialValues={{
              ...fields,
              name: '',
              identifier: '',
              status: 'ACTIVE',
            }}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={createAdminGroupValidate}
          >
            <AddEditAdminGroupForm
              isLoading={isLoadingCreate || isLoadingUpdate}
              permissions={permissions ? permissions : {}}
              fields={fields}
              isNewGroup={isNewGroup}
            />
          </Formik>
        }
      </FormWrapper>
    </Page>
  )
}

export default CreateEditNewAdminGroupPage;